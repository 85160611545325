export enum EPaths {
    SIGN_IN = '/account/signin',
    SIGN_UP = '/account/signup',
    FORGOT_PASSWORD = '/account/forgot',
    RESET_PASSWORD = '/account/reset',
    CUSTOMERS_SIGN_UP = '/customers/signup',
    EMAIL_INVOICE_PDF = '/email_invoice_pdf/:supplier_id/:transaction_id',
    CREATE_SELF_ONBOARD_BUYER = '/signup/:email/:code(/:fromSubdomain)',
    RECEIVING_TOOL = '/order/edit/:order_urlsafe',
    ORDER_GUIDE = '/store/:vendorID/:orderGuide',
    ORDER_GUIDE_WILD = '/store/:vendorID/*',
    SEARCH = '/search',
    ORDER_HISTORY = '/orders',
    ORDER_DETAILS = '/order_detail/:order_urlsafe',
    DIRECT_CHAT = '/inbox',
    SETTINGS = '/settings',
    HOME = '/home',
    ADMIN = '/admin',
    SETUP = '/setup',
    INVALID_VERSION = '/invalid_version',
    SHOP = '/shop',
    MAINTENANCE = '/maintenance',
    BILLS = '/bills',
    INVOICES = '/invoices',
    UPLOADS = '/uploads',
    UPLOAD = '/uploads/new',
    SCANS_REVIEW = '/uploads/:invoiceKey/review',
    SUPPLIER_CATALOG_IMPORT = '/catalog/:supplierUrlsafeKey/import',
    CATALOG_MANAGEMENT = '/catalog/:supplierUrlsafeKey',
    CATALOG = '/catalog',
    ACCEPT_MANDATE = '/mandate/:id',
}

export enum ESettingsPaths {
    ACCOUNT = '/account',
    BUYER = 'buyer',
    LOCATIONS = 'locations',
    MEMBERS = 'members',
    INTEGRATIONS = '/integrations',
}

export enum ESetupPaths {
    CATALOG = 'catalog',
    CATALOG_ADD_ITEMS = 'catalog/add-items',
    CATALOG_ADD_ITEMS_CREATE = 'catalog/add-items/create',
    CATALOG_ADD_ITEMS_UPLOAD = 'catalog/add-items/upload',
    CATALOG_COMPLETE = 'catalog/complete',
    SUPPLIERS = 'suppliers'
}

export const enum ARRoutePaths {
    AR = 'ar',
    AR_HOME = 'home',
    AR_INVOICES = 'invoices',
    AR_DRAFT_INVOICES = 'draft-invoices',
    AR_BILLS = 'bills',
    AR_PAYOUTS = 'payouts',
    AR_CUSTOMERS = 'customers',
    AR_SETTINGS = 'settings',
    AR_CUSTOMER_NOTIFICATIONS = 'customer_notifications',
    AR_SUPPLIER_NOTIFICATIONS = 'supplier_notifications',
    AR_BANKING = 'banking',
    AR_ACCOUNTS = 'account',
    BALANCES = 'balances',
    AR_BRANDING = 'branding',
    AR_INTEGRATION = 'integrations',
    PAYMENT_METHOD = 'payment_method',
}

export const enum APRoutePaths {
    AP_SETTINGS = 'customer_settings',
    AP_PAYMENT_METHODS = 'payment_methods',
    AP_SUPPLIERS = 'suppliers',
    AP_BILLS = 'ap-bills',
}
