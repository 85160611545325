import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import { Banner, Button, EBannerType, Input, toast, Typography } from '@notch-ordering/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { getGpoId } from '@v2/utils/GPOUtils';
import { attachAdyenPaymentMethod } from '@/ar/network/AccountsReceivable.network';
import { FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY, useGetCustomer } from '@/ar/hooks/queries/CustomerQueries.hook';
import { FETCH_BALANCES_QUERY_KEY } from '@/ar/hooks/queries/BalanceQueries.hook';
import { FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@/ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { RadioButton } from '@/components/shared';
import { MandateTypes } from '../../CreateAdyenPaymentMethodConstants';

export interface AdyenACHMandateModalProps {
    handleClose: () => void,
    bankData: EncryptedBankData,
    customerID: string,
    supplierID: string,
}

export type EncryptedBankData = {
    encryptedOwnerName: string,
    encryptedRoutingNumber?: string,
    encryptedAccountNumber: string,
};

type AttachPaymentMethodError = { error: string };

export const AdyenACHMandateModal: React.FC<AdyenACHMandateModalProps> = ({ handleClose, bankData, customerID, supplierID }) => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const supplierIDToUse = supplierID ?? supplierLoginData?.supplier_id;
    const queryClient = useQueryClient();
    const attachPaymentMethodMutation = useMutation(attachAdyenPaymentMethod);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [selected, setSelected] = useState<MandateTypes>(null);
    const isOfflineMandateEnabled = !!supplier?.is_offline_mandate_enabled;
    const gpoId = getGpoId(supplierLoginData);
    const { data: customer } = useGetCustomer({
        customerID,
        supplierID,
        gpoId
    });

    const resetState = () => {
        setHasError(false);
        setSelected(null);
        setErrorMessage('');
    };
    const onClose = () => {
        resetState();
        handleClose();
    };

    const onSubmit = async (): Promise<void> => {
        attachPaymentMethodMutation.mutate({
            body: {
                bankData: {
                    type: 'us_bank_account',
                    accountRole: 'checking',
                    ownerName: bankData?.encryptedOwnerName,
                    routingNumber: bankData?.encryptedRoutingNumber,
                    accountNumber: bankData?.encryptedAccountNumber
                },
                customerId: customerID,
                supplierId: supplierIDToUse,
                isMandateAttestedOffline: selected === 'offline'
            }
        }, {
            onSuccess: async () => {
                toast.show({
                    message: 'Bank account added successfully',
                });
                queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                queryClient.invalidateQueries([FETCH_BALANCES_QUERY_KEY]);
                queryClient.invalidateQueries([FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
                onClose();
            },
            onError: (error: AxiosError<AttachPaymentMethodError>) => {
                setHasError(true);
                const message = error?.response?.data?.error;
                if (message.includes('Code')) {
                    setErrorMessage(message);
                }
            }
        });
    };

    return <React.Fragment>
        <div className="flex flex-col gap-2 px-8 py-6">
            {!!hasError
                && <Banner alertType={EBannerType.ERROR}
                    className="px-2 rounded-none mb-2"
                    title={<Typography as="div" className="text-red-500 text-2">
                        Unable to add payment method. Please contact Notch.
                    </Typography>}
                    body={<Typography as="div" className="text-gray-600 text-1">
                        {errorMessage}
                    </Typography>} />}
            <Typography variant="LG-1" className="text-gray-600 mb-0">A signed Pre-authorized Debit (PAD) agreement is required before a bank transfer can be initiated.</Typography>
            <div className="flex flex-col gap-2 items-center ">
                <Button
                    className="text-left flex py-4 text-gray-700 w-full"
                    variant="TERTIARY_OUTLINED"
                    size="MEDIUM"
                    onClick={() => setSelected(MandateTypes.EMAIL)}>
                    <RadioButton id="email"
                        name="email"
                        label=""
                        isChecked={selected === MandateTypes.EMAIL}
                        onChecked={() => setSelected(MandateTypes.EMAIL)}/>
                    <div className="flex-row gap-3 items-center">
                        <Typography variant="LG-1" className="text-gray-700 mb-0">Send your customer a digital PAD agreement</Typography>
                        <Typography variant="BASE" className="text-gray-600 mt-1 mb-2">Your customer will receive a simple one-click PAD agreement. You can begin charging them as soon as the agreement is accepted. </Typography>
                        <Input
                            name="customerEmail"
                            id="customerEmail"
                            label="Email"
                            type="TEXT"
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            disabled
                            required
                            placeholder={customer?.email}/>
                    </div>
                </Button>
                {isOfflineMandateEnabled && <Button
                    className="text-left flex py-4 text-gray-700 w-full"
                    variant="TERTIARY_OUTLINED"
                    size="MEDIUM"
                    onClick={() => setSelected(MandateTypes.OFFLINE)}>
                    <RadioButton id="offline"
                        name="offline"
                        label=""
                        isChecked={selected === MandateTypes.OFFLINE}
                        onChecked={() => setSelected(MandateTypes.OFFLINE)}/>
                    <div className="flex-row gap-3">
                        <Typography variant="LG-1" className="text-gray-700 mb-0">I confirm I have an existing approved PAD agreement stored for this customer.</Typography>
                        <Typography variant="BASE" className="text-gray-600 mt-1 mb-0">No new agreement is required. You can start charging immediately.</Typography>
                    </div>
                </Button>}
            </div>
        </div>
        <div className="w-full h-px bg-gray-200" />
        <div className="pt-4 mx-6 flex justify-self-end">
            <Button
                className="mr-3 cursor-pointer"
                type="button"
                as="div"
                onClick={onClose}
                variant="TERTIARY_FILLED"
                size="MEDIUM"
                minWidth="w-auto">
                Cancel
            </Button>
            <Button
                disabled={selected === null}
                variant="SECONDARY"
                size="MEDIUM"
                minWidth="w-auto"
                type="submit"
                className="flex items-center"
                loading={attachPaymentMethodMutation.isLoading}
                onClick={onSubmit}>
                {selected === MandateTypes.OFFLINE ? 'Save' : 'Save & Request Signature' }<ArrowRightIcon className="h-5 w-5 ml-1"/>
            </Button>
        </div>
    </React.Fragment>;
};
