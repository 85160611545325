import axios, { AxiosResponse } from 'axios';
import Stripe from 'stripe';
import { TUseGetCustomersQuery } from '@ar/hooks/queries/CustomerQueries.hook';
import { X_AR_AUTOMATION_TOKEN_HEADER } from '@v2/utils/AxiosUtils';
import { LanguageType } from '../pages/NotificationsPageV2/NotificationsDefault/NotificationsDefaultConstants';
import { useSupplierStore } from '../stores/SupplierStore';

export const ACCOUNTS_RECEIVABLE_API = process.env.BASE_URLS.accountsReceivableApi || 'http://localhost';
export const unAuthAxiosInstance = axios.create();
export const serverTokenHeader = process.env.AR_SERVER_TOKENS ? `Bearer ${process.env.AR_SERVER_TOKENS}` : undefined;

export enum EIntegrationType {
    QBO = 'qbo',
    QBOWORKATO = 'qbo_workato',
    QBD = 'qbd',
    Fidelio = 'fidelio',
    Spire = 'spire',
    NetSuite = 'netsuite',
    Sage300 = 'sage_300',
    CsvImport = 'csv_import',
    PdfImport = 'pdf_import',
    Xero = 'xero',
    Dynamics365 = 'dynamics365'
}

export enum EntityTypeActivity {
    invoice = 'invoice',
    credit = 'credit',
    refund = 'refund',
    customer = 'customer',
    supplier = 'supplier',
}

export type EntityTypeActivityType = 'invoice' | 'credit' | 'refund' | 'customer' | 'supplier';

export type RuleType = 'before_due_date' | 'on_due_date' | 'after_due_date';

export interface Address {
    line1: string,
    line2: string,
    city: string,
    postal_code: string,
    state: string,
    country: string,
}

export type OptionalAddress = Partial<Address>;

type Balances = {
    pending_balance: number,
    in_transit_balance: number,
    paid_balance: number,
};

type PaymentMethods = {
    cards_visa: number,
    cards_amex: number,
    cards_mastercard: number,
    cards_others: number,
    bank_debit: number,
    none_payment_methods: number,
};

type AutoCollection = {
    auto_pay_enable_on_invoice_issue: number,
    auto_pay_enable_on_invoice_due: number,
    auto_pay_enable_weekly: number,
    auto_pay_enable_bi_weekly: number,
    auto_pay_enable_monthly: number,
    auto_pay_disabled: number,
};

type TermsReport = {
    terms: {
        'net-0': number,
        'net-15': number,
        'net-3': number,
        'net-30': number,
        'net-60': number,
    },
};

type FailureData = {
    decline_code: string,
    description: string,
    next_step: string,
    count: number,
};

type FailedPaymentReport = {
    failures: FailureData[],
};

type OverviewCollectionReport = {
    open: number,
    num_open: number,
    failed: number,
    num_failed: number,
    overdue: number,
    num_overdue: number,
    scheduled_manual: number,
    num_scheduled_manual: number,
    scheduled_auto_pay: number,
    num_scheduled_auto_pay: number,
    paid_in_last_day: number,
    num_paid_in_last_day: number,
    num_paid: number,
    num_partial_paid: number,
    num_off_platform: number,
};

type ForecastPaymentReport = {
    [date: string]: {
        scheduled_manual: number,
        scheduled_auto_pay: number,
    },
};

type PaymentStatus = {
    pending: number,
    failed: number,
    partial_charged: number,
    charged: number,
    refunded: number,
};

type CollectedPaymentReport = {
    [date: string]: PaymentStatus,
};

type ReportsData = {
    balances: Balances | null,
    payment_methods: PaymentMethods | null,
    auto_collection: AutoCollection | null,
    terms_report: TermsReport | null,
    failed_payment_report: FailedPaymentReport | null,
    overview_collection_report: OverviewCollectionReport | null,
    forecast_payment_report: ForecastPaymentReport | null,
    collected_payment_report: CollectedPaymentReport | null,
};

export type CustomerPaymentRuleReminder = {
    id: string,
    rule_type: string,
    description: string,
};

export type SupplierMembership = {
    supplier_id : string,
    supplier_name : string,
};

export type GPOMembership = {
    id : string,
    logo : string,
    name : string,
};

export enum InvoicePDFGenerationType {
    Disabled = 'disabled',
    OnDemand = 'on_demand',
    Always = 'always',
}

export type SupplierData = {
    id: string,
    name: string,
    email: string,
    phone: string,
    billing_address: Address,
    onboarding_link: string,
    api_token: string,
    currency: string,
    bank_name: null,
    bank_account_number: string | null,
    invoice_notifications: boolean,
    add_payment_links_on_invoice_notifications: boolean,
    customer_payment_link_reminder_rules: CustomerPaymentRuleReminder[],
    doing_business_as: string | null,
    statement_descriptor: string,
    statement_descriptor_suffix: string,
    statement_descriptor_for_cards: string,
    statement_descriptor_for_bank_debits: string,
    auto_collection_for_new_customers: boolean,
    allow_retries: boolean,
    allow_use_of_others_payment_methods: boolean,
    max_number_of_retries: number | null,
    max_number_of_days_before_stop_retries: number | null,
    integration_name: string | null,
    enable_cash_application: boolean,
    last_synced_at: string | null,
    integration_with_invoices_pdf: boolean,
    integration_with_invoices_items: boolean,
    logo_url: string,
    secondary_logo_url: string | null,
    website_url: string | null,
    primary_color: string | null,
    secondary_color: string | null,
    allow_customer_pay_with_cards: boolean,
    allow_customer_pay_with_bank_debits: boolean,
    reports: ReportsData,
    pending_balance: number | null,
    payment_receiver_id: string | null,
    enable_payment_notifications: boolean,
    enable_accounting_internal_customer_name: boolean,
    notifications_language: LanguageType,
    pause_notifications?: boolean,
    account_id?: string,
    invoice_pdf_generation?: InvoicePDFGenerationType | null,
    allow_edit_customer?: boolean,
    is_offline_mandate_enabled?: boolean,
};

export type CreateSupplierResponse = {
    supplier: SupplierData,
    dashboard_url: string,
    username: string,
    password: string,
};

export type GetSupplierResponse = SupplierData;

type ConnectedSuppliersPaymentMethod = {
    supplierId: string,
    isPreferred: boolean,

};

type PaymentMethod = {
    id: string,
    brand: string,
    exp_month: number,
    exp_year: number,
    last4: string,
    is_primary: boolean,
    payment_source_id: string,
    connectedSuppliers?: ConnectedSuppliersPaymentMethod[],
};

type CustomerData = {
    id: string,
    name: string,
    first_name?: string,
    last_name?: string,
    currency?: Currency,
    email: string,
    address: Address,
    has_payment_method: boolean,
    auto_collection: boolean,
    weekly_statement_email: boolean,
    default_payment_method?: PaymentMethod,
    terms: number,
    charge_invoice_when: string,
    charge_invoice_on_weekday?: string,
    charge_invoice_on_monthday?: number,
    payment_link: boolean,
};

export type NotificationsData = {
    rules: NotificationsRules[],
};

export type NotificationsRules = {
    type?: RuleType,
    interval: number,
};

export type GetNotificationsResponse = NotificationsData;
type UpdateNotificationsResponse = NotificationsData;
type UpdateSupplierResponse = SupplierData;
type UpdateCustomerResponse = CustomerData;

export type CustomersWithCustomNotificationsData = {
    customers: Customers[],
};

export type Customers = {
    id: string,
    businessName: string,
    editedSettings: EditedSettings,
};

export type EditedSettings = {
    fields: string[],
    count: number,
};

export type UpdateSupplierData = OptionalAddress & {
    name?: string,
    email?: string,
    phone?: string,
    logo_url?: string,
    secondary_logo_url?: string,
    invoice_notifications?: boolean,
    statement_descriptor_suffix?: string,
    statement_descriptor?: string,
    add_payment_links_on_invoice_notifications?: boolean,
    doing_business_as?: string,
    integration_name?: string,
    cash_application_webhook_url?: string,
    primary_color?: string,
    secondary_color?: string,
    allow_customer_pay_with_bank_debits?: boolean,
    enable_payment_notifications?: boolean,
    notifications_language?: LanguageType,
    pause_notifications?: boolean,
};

export type UpdateCustomerData = OptionalAddress & {
    business_name?: string,
    first_name?:string,
    last_name?:string,
    email?: string,
    auto_collection?: boolean,
    weekly_statement_email?: boolean,
    terms?: number,
    charge_invoice_when?: string,
    charge_invoice_on_weekday?: string,
    charge_invoice_on_monthday?: number,
    invoice_notifications?: boolean,
    disable_reminders?: boolean,
    cc_emails?: string[],
    is_surcharge_enabled?: boolean,
    external_id?: string,
    is_active?: boolean,
    phone?: string,
};

type UpdateTransactionData = {
    do_no_charge: boolean,
};

export type PayInvoicesData = {
    transaction_ids: string[],
    is_lumped: boolean,
    payment_method_id?: string,
    installments_schedule?: string[],
};

export type PayInvoicesResponse = {
    id: string,
    charged: boolean,
    error_description?: string,
    decline_code?: string,
    next_step?: string,
};

type FlagInvoicesAsPaidData = {
    paid_offline: boolean,
};

export type GetPaymentMethodResponse = PaymentMethod & {
    type: string,
    mandate_id: string,
    mandate_created_at: Date,
    mandate_status: MandateStatus,
};

export type GetPaymentMethodsResponse = {
    payment_methods: GetPaymentMethodResponse[],
};

export type CreateCustomerData = OptionalAddress & {
    supplier_id: string,
    external_id: string,
    business_name: string,
    email?: string,
    first_name?: string,
    last_name?: string,
    phone?: string,
    terms?: number,
    currency?: string,
    gpo_ids?: string[],
    is_active?: boolean,
    platformId?: string | null,
    platform_internal_name?: string | undefined,
};

type CreateCustomerResponse = {
    customer_id: string,
};

type SignUpCustomerData = CreateCustomerData & {
    email: string,
    password: string,
    account_name?:string,
};

type SignUpCustomerResponse = {
    customer_id: string,
    account_id: string,
    created_at: string,
    updated_at: string,
    email: string,
    account_name: string | null | undefined,
};

type CreateTransactionData = {
    supplier_id: string,
    customer_id: string,
    external_id?: string,
    invoice_number?: string,
    invoice_date: string,
    due_date: string,
    total?: number,
    terms?: number,
    offline_paid_amount?: number,
};

type CreateTransactionResponse = {
    id: string,
};

enum DayOfWeek {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
}
export type CustomerSupplierMapping = {
    supplierId: string,
    supplierName: string,
    auto_collection: boolean,
    charge_invoice_when: string,
    charge_invoice_on_weekday: DayOfWeek | null,
    charge_invoice_on_monthday: number | null,
    platformId: string | null,
    platform_internal_name: string | null,
    isActive: boolean,
    isSurchargeEnabled: boolean,
};
export type GetCustomerResponse = {
    id: string,
    external_id: string,
    name: string,
    first_name?: string,
    last_name?: string,
    currency?: Currency,
    email: string,
    cc_emails: string[],
    phone: string,
    address: Address,
    has_payment_method: boolean,
    weekly_statement_email: boolean,
    default_payment_method?: GetPaymentMethodResponse,
    terms: number,
    auto_collection: boolean,
    charge_invoice_when: string,
    charge_invoice_on_weekday?: string,
    charge_invoice_on_monthday?: number,
    invoice_notifications: boolean | null,
    disable_reminders?: boolean,
    platform_internal_name?: string,
    is_surcharge_enabled: boolean,
    supplier_mappings: CustomerSupplierMapping[],
    is_active?: boolean,
};

export type GetCustomersResponse = {
    customers: GetCustomerResponse[],
    limit: number,
    offset: number,
    total: number,
};

export type GetSuppliersResponse = {
    suppliers: GetSupplierResponse[],
    limit: number,
    offset: number,
    total: number,
};

type AttachPaymentMethodData = {
    token_id: string,
    default: boolean, // IsPrimary
};

type AttachPaymentMethodResponse = {
    payment_method_id: string,
};

type UpdatePaymentMethodData = {
    customer_id: string,
    is_primary: boolean,
};

export type GetPayoutTransaction = {
    reporting_category: string,
    invoice_number: string[],
    customer_name: string,
    customer_id: string,
    external_id: string,
    type_of_payment: string,
    gross: number,
    fee: number,
    net: number,
    created_at: Date,
    description: string,
    last4: string,
    payment_source: string,
};

export type Payout = {
    id: string,
    gross: number,
    fee: number,
    net: number,
    refunds: number,
    received_at: Date,
    number_of_transactions: number,
    number_of_invoices: number,
    number_of_refunds: number,
    transactions?: GetPayoutTransaction[],
    status: string,
    last4?: string,
    supplier_id: string,
    supplier_name: string,
};

type RefundPaymentBody = {
    transaction_id: string,
    amount: number,
    external_id?: string,
};

export type GetPayoutsResponse = {
    payouts: Payout[],
    total: number,
};

export type GetBalancesResponse = {
    customers: {
        account_with_email_exists: boolean,
        auto_collection: boolean,
        credit_applied: number,
        email: string,
        external_id: string,
        failed: number,
        id: string,
        invoice_notifications: boolean | null,
        has_account?: boolean,
        name: string,
        open: number,
        overdue: number,
        paid: number,
        payment_method_brand: string,
        payment_method_exp_month: string,
        payment_method_exp_year: string,
        payment_method_last4: string,
        payment_method_type: string,
        purchased: number,
        refunded: number,
        terms: number,
    }[],
    limit: number,
    offset: number,
    total: number,
    total_without_filter?: number,
};

/**
 * @deprecated (axios will build the query param for you)
 * Used to build up query string.
 */
export function getQueryParam(key: string, value: string | number | boolean, isFirst: boolean = false): string {
    return `${isFirst ? '?' : '&'}${key}=${value}`;
}

/**
 * create_supplier in Retool
 */
export async function signUpSupplier(body: unknown): Promise<CreateSupplierResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/sign_up`, body).then((response) => response.data);
}

export type GetCustomerQuery = {
    customerID: string,
    supplierID: string,
    gpoId?: string,
    token?: string,
    supplierIdFilter?: string,
};

export type GetCustomerByCustomerAuthQuery = {
    customerID: string,
    token?: string,
};

/**
 * get_customer_rest in Retool
 */
export async function getCustomer(params: GetCustomerQuery): Promise<GetCustomerResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/${params.customerID}`, {
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: params.token ?? null,
        },
        params: {
            supplier_id: params.supplierID,
            supplier_id_filter: params.supplierIdFilter,
            gpo_id: params.gpoId
        }
    }).then((response) => response.data);
}

export async function getCustomerByCustomerAuth(params: GetCustomerByCustomerAuthQuery): Promise<GetCustomerResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/${params.customerID}`, {
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: params.token ?? null,
        },
        params: {
            customer_id: params.customerID,
        }
    }).then((response) => response.data);
}
export type UpdateSupplierParams = {
    supplierID: string,
    body: UpdateSupplierData,
};

export type UpdateNotificationsParams = {
    supplierID: string,
    body: NotificationsData,
};

export type GetCustomersWithCustomNotificationsParams = {
    supplierID: string,
};

/**
 * update_supplier in Retool
 */
export async function updateSupplier(params: UpdateSupplierParams): Promise<UpdateSupplierResponse> {
    const { supplierID, body } = params;
    return axios.patch(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}?supplier_id=${supplierID}`, body).then((response) => response.data);
}

export type SendNotificationRequestParams = {
    transactionID: string,
    supplierID: string,
    gpoId?: string,
};

/**
 * update AR notifications
 */
export async function updateSupplierNotificationRules(params: UpdateNotificationsParams): Promise<UpdateNotificationsResponse> {
    const { supplierID, body } = params;
    return axios.put(`${ACCOUNTS_RECEIVABLE_API}/v1/notifications/${supplierID}`, body).then((response) => response.data);
}

export type UpdateCustomerNotificationsParams = {
    supplierID: string,
    customerID: string,
    body: NotificationsData,
};

/**
 * update AR notifications for customers
 */
export async function updateCustomerNotificationRules(params: UpdateCustomerNotificationsParams): Promise<UpdateNotificationsResponse> {
    const { supplierID, customerID, body } = params;
    return axios.put(`${ACCOUNTS_RECEIVABLE_API}/v1/notifications/${supplierID}/${customerID}`, body).then((response) => response.data);
}

/**
 * Get custom notifications for suppliers customers
 */
export async function getCustomersWithCustomNotifications(params: GetCustomersWithCustomNotificationsParams): Promise<CustomersWithCustomNotificationsData> {
    const { supplierID } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/customers_with_custom_notifications`, { params }).then((response) => response.data);
}

/**
 * send_payment_link in Retool
 */
export async function sendInvoiceNotification(params: SendNotificationRequestParams): Promise<Record<string, never>> {
    const { transactionID, supplierID, gpoId } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}/email_invoice`, {}, {
        params: {
            supplier_id: supplierID,
            gpo_id: gpoId,
        }
    }).then((response) => response.data);
}

export type InvoicePaidEventSyncManuallyParams = {
    transactionID: string,
    supplierID: string,
    gpoId?: string,
};

/**
 * not such function in Retool
 */
export async function invoicePaidEventSyncManually(params: InvoicePaidEventSyncManuallyParams): Promise<Record<string, never>> {
    const { transactionID, supplierID, gpoId } = params;
    return axios.post(
        `${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}/re_sync`,
        {},
        {
            params: {
                supplier_id: supplierID,
                gpo_id: gpoId,
            }
        }

    ).then((response) => response.data);
}

export type MakeInvoiceEditableParams = {
    transactionID: string,
    supplierID: string,
};

export type MakeInvoiceEditableResponse = {
    draft_invoice_id?: string,
    draft_invoice_status?: string,
};

export async function makeInvoiceEditable(params: MakeInvoiceEditableParams): Promise<MakeInvoiceEditableResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${params.transactionID}/make_editable`, {}, {
        params: {
            supplier_id: params.supplierID,
        }
    }).then((response) => response.data);
}

/**
 * update_customer in Retool
 */
export async function updateCustomer({ customerID, supplierID, ...body }: { customerID: string, supplierID: string } & UpdateCustomerData): Promise<UpdateCustomerResponse> {
    return axios.put(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/${customerID}?supplier_id=${supplierID}`, body).then((response) => response.data);
}

export async function updateCustomerByCustomerAuth({ customerID, ...body }: { customerID: string } & UpdateCustomerData): Promise<UpdateCustomerResponse> {
    return axios.put(
        `${ACCOUNTS_RECEIVABLE_API}/v1/customers/${customerID}`,
        body,
        {
            params: {
                customer_id: customerID
            }
        }
    ).then((response) => response.data);
}

export type UpdateTransactionsParams = {
    body: {
        do_no_charge?: boolean,
        transaction_ids: string[],
    },
    customerID?: string,
    supplierID?: string,
};

/**
 * update_transactions in Retool
 */
export async function updateTransactions(params: UpdateTransactionsParams): Promise<Record<string, never>> {
    const { supplierID, body, customerID } = params;
    return axios.patch(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions`, body, {
        params: {
            supplier_id: supplierID,
            customer_id: customerID
        }
    }).then((response) => response.data);
}

export type UpdateTransactionRequestParams = {
    supplierID?: string,
    customerID?: string,
    gpoId?: string,
    transactionID: string,
    body: UpdateTransactionData,
};
/**
 * update_transaction in Retool
 */
export async function updateTransaction(params: UpdateTransactionRequestParams): Promise<Record<string, never>> {
    const { supplierID, transactionID, body, customerID, gpoId } = params;
    return axios.patch(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}`, body, {
        params: {
            supplier_id: supplierID,
            customer_id: customerID,
            gpo_id: gpoId
        }
    }).then((response) => response.data);
}

export type GetTransactionSortBy = ''
| '-auto_collection' | 'auto_collection'
| '-business_name' | 'business_name'
| '-decline_code' | 'decline_code'
| '-due_date' | 'due_date'
| '-external_id' | 'external_id'
| '-invoice_date' | 'invoice_date'
| '-invoice_number' | 'invoice_number'
| '-payment_method_id' | 'payment_method_id'
| '-status' | 'status'
| '-synced_with_cash_application' | 'synced_with_cash_application'
| '-terms' | 'terms'
| '-total' | 'total';

export type GetTransactionStatus = 'pending'
| 'partial_charged' | 'partial_refund'
| 'charged' | 'refunded'
| 'failed' | 'do_not_charge' | 'processing'
| 'scheduled' | 'charged_offline' | 'requires_action' | 'Unsupported 3DS Card' | 'disputed';

export enum TransactionStatus {
    Pending = 'pending',
    PartialCharged = 'partial_charged',
    PartialRefund = 'partial_refund',
    Charged = 'charged',
    Refunded = 'refunded',
    Failed = 'failed',
    DoNotCharge = 'do_not_charge',
    Scheduled = 'scheduled',
    ChargedOffline = 'charged_offline',
    RequiresAction = 'requires_action',
    Disputed = 'disputed',
    // this is a special case for the UI since the API doesn't return the requires_action status
    RequiresAction3DS = 'Unsupported 3DS Card',
    Processing = 'processing',
}

export const enum Integrations {
    Fidelio = 'fidelio',
    Netsuite = 'netsuite',
    Spire = 'spire',
}

export type GetTransactionParams = {
    supplier_id?: string,
    gpo_id?: string,
    supplier_id_filter?: string,
    supplier_ids?: string[],
    id?: string,
    customer_id?: string,
    decline_code?: string,
    due_date_end?: string | Date,
    due_date_start?: string | Date,
    external_id?: string,
    has_auto_collection?: boolean,
    has_payment_method?: boolean,
    invoice_date_end?: string | Date,
    invoice_date_start?: string | Date,
    invoice_number?: string,
    limit?: number,
    offset?: number,
    overdue?: boolean,
    sort_by?: GetTransactionSortBy,
    status?: GetTransactionStatus,
    synced_with_cash_application?: boolean,
    terms?: number,
};

export interface GetTransactionsResponse {
    transactions: Transaction[],
    limit: number,
    offset: number,
    total: number,
}

export interface TransactionCharge {
    amount: number,
    payment_method :{
        brand: string,
        last4: string,
    } | null,
    created_at: string,
    status: string,
}

export interface EstimatedSurcharge {
    paymentType: {
        type: string,
    },
    estimatedSurcharge: number | null,
}

export interface Transaction {
    id: string,
    external_id: string,
    customer_id: string,
    business_name: string,
    email: string,
    invoice_number: string,
    po_number: string,
    invoice_date: string,
    due_date: string,
    error_message: string,
    decline_code: string,
    error_message_next_step: string,
    processing_fee: number,
    total_fee: number,
    total: number,
    outstanding_amount: number,
    paid: number,
    paid_offline: number,
    refunded: number,
    credits: number,
    status: string,
    refunded_at: string | null,
    charged_at: string | null,
    failed_at: string | null,
    terms: number,
    payment_method_id: string,
    payment_method_type: string,
    brand: string,
    last4: string,
    sync: boolean,
    auto_collection: boolean,
    credits_applied: {
        external_id: string,
        amount: number,
        invoice_date: string,
    }[],
    charges: TransactionCharge[],
    invoice_sent_at: string | null,
    payment_link_sent_at: string | null,
    payment_link_url: string,
    payouts: {
        id: string,
        amount: number,
        status: string,
        received_at: string,
    }[],
    supplier_id?: string,
    refund_details: {
        stripe_id: string,
    }[],
    invoice_pdf_url: string | null,
    estimated_surcharges: EstimatedSurcharge[],
    surcharge_amount: number | null,
    is_surcharge_enabled: boolean,
    draft_invoice_id: string | null,
    attachments: string[],
}

/**
 *
 */
export async function getTransactions(params: GetTransactionParams): Promise<GetTransactionsResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions`, {
        params,
    }).then((response) => response.data);
}

export type GetTransactionByIDParams = {
    supplier_id?: string,
    transactionID?: string,
    customer_id?: string,
    gpo_id?: string,
};

/**
 * Schema: https://docs.rutterapi.com/rest/2023-03-14/attachments
 */
export type AccountingInvoiceAttachment = {
    id: string,
    platform_id: string,
    attached_to_id: string,
    file_name: string,
    file_url: string,
    attached_to_type: string,
    created_at: string,
};

export type GetAttachmentsResponse = {
    attachments: AccountingInvoiceAttachment[],
    invoice_pdf_link?: string,
};

/**
 *
 */
export async function getTransaction(params: GetTransactionByIDParams): Promise<Transaction> {
    const { transactionID } = params;

    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}`, {
        params,
    }).then((response) => response.data);
}

export type GetTransactionItemType = 'invoice' | 'credit' | string;

export type GetTransactionItem = {
    id: string,
    external_id: string,
    description: string,
    quantity: number,
    weight: number,
    is_weight_item: boolean,
    tax_amount: number,
    price: number,
    total: number,
    rank: number,
    type:GetTransactionItemType,
};

export type GetTransactionItemsResponse = {
    items: GetTransactionItem[],
};

export type GetTransactionItemsParams = {
    supplier_id?: string,
    customer_id?: string,
    transactionID?: string,
    gpo_id?: string,
};
/**
 *
 */
export async function getTransactionItems(params: GetTransactionItemsParams): Promise<GetTransactionItemsResponse> {
    const { transactionID } = params;

    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}/items`, {
        params,
    }).then((response) => response.data);
}

export type UpdateTransactionItemBody = Partial<GetTransactionItem>;

export type UpdateTransactionItemParams = {
    supplierID: string,
    itemID: string,
    body: UpdateTransactionItemBody,
};

export async function updateTransactionItem(params: UpdateTransactionItemParams): Promise<GetTransactionItem> {
    const { supplierID, itemID, body } = params;

    return axios.patch(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/items/${itemID}`, body, {
        params: {
            supplier_id: supplierID,
        }
    }).then((response) => response.data);
}

export type CreateTransactionItemsBody = {
    items: GetTransactionItem[],
};

export type CreateTransactionItemsParams = {
    supplierID: string,
    transactionID: string,
    body: CreateTransactionItemsBody & { add_ons?: boolean },
};

export async function createTransactionItems(params: CreateTransactionItemsParams): Promise<GetTransactionItemsResponse> {
    const { supplierID, transactionID, body } = params;

    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}/items`, body, {
        params: {
            supplier_id: supplierID,
        }
    }).then((response) => response.data);
}

export type DeleteTransactionItemParams = {
    supplierID: string,
    itemID: string,
};

export async function deleteTransactionItem(params: DeleteTransactionItemParams): Promise<void> {
    const { supplierID, itemID } = params;
    return axios.delete(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/items/${itemID}`, {
        params: {
            supplier_id: supplierID,
        }
    }).then((response) => response.data);
}

export type DeleteTransactionItemsParams = {
    transactionID: string,
    supplierID: string,
    body: {
        itemIds: string[],
    },
};

export async function deleteTransactionItems(params:DeleteTransactionItemsParams): Promise<void> {
    const { transactionID, supplierID, body } = params;
    return axios.delete(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}/items`, {
        params: {
            supplier_id: supplierID,
        },
        data: body,
    }).then((response) => response.data);
}

type ArActivityPayloadType = {
    invoice_pdf?: string,
} | {
    status: string,
    stripe_id: string,
    failure_message?: string,
};
export enum ActivityType {
    InvoicePdfAttached = 'invoice_pdf_attached',
    RefundStatus = 'refund_status',
    InvoicePdfGenerationRequested = 'invoice_pdf_generation_requested',
}
export interface Activity {
    id: string,
    entity_id: string,
    type: string | ActivityType,
    description: string,
    created_at: string,
    payload:ArActivityPayloadType,
}
export type GetActivityResponse = {
    activities: Activity[],
};

export type GetTransactionActivityParams = {
    supplier_id?: string,
    customer_id?: string,
    gpo_id?: string,
    entity_id?: string,
    entity_type?: EntityTypeActivityType,
};

/**
 *
 */
export async function getTransactionActivity(params: GetTransactionActivityParams): Promise<GetActivityResponse> {
    const { entity_id: entityID } = params;

    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/activity/${entityID}`, {
        params,
    }).then((response) => response.data);
}

export type GetTransactionPayoutsParams = {
    supplier_id?: string,
    transactionID?: string,
    customer_id?: string,
    gpo_id?: string,

};

export type TransactionPayout = {
    id: string,
    gross: number,
    fee: number,
    net: number,
    received_at: string,
    status: string,
};

export type GetTransactionPayoutsResponse = {
    payouts: TransactionPayout[],
};

/**
 *
 */
export async function getTransactionPayouts(params: GetTransactionPayoutsParams): Promise<GetTransactionPayoutsResponse> {
    const { transactionID } = params;

    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}/payouts`, {
        params,
    }).then((response) => response.data);
}

export type PayInvoicesParams = {
    supplierID: string,
    gpoId?: string,
    body: PayInvoicesData,
};

/**
 * pay_invoices in Retool
 */
export async function payInvoices(params: PayInvoicesParams): Promise<PayInvoicesResponse[]> {
    const { supplierID, body, gpoId } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/pay`, body, {
        params: {
            supplier_id: supplierID,
            gpo_id: gpoId
        }
    }).then((response) => response.data);
}

/**
 * flag_invoices_as_paid in Retool
 */
export async function flagInvoicesAsPaid(transactionID: string, supplierID: string, body: FlagInvoicesAsPaidData): Promise<Record<string, never>> {
    return axios.patch(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/${transactionID}?supplier_id=${supplierID}`, body).then((response) => response.data);
}

export type DeletePaymentMethodParams = {
    paymentMethodID: string,
    customerID: string,
    supplierID: string,
};

/**
 * delete_payment_method in Retool
 */
export async function deletePaymentMethod({ paymentMethodID, customerID, supplierID }: DeletePaymentMethodParams): Promise<void> {
    return axios.delete(`${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/${paymentMethodID}?customer_id=${customerID}&supplier_id=${supplierID}`, {}).then((response) => response.data);
}

export type SendMandateEmailParams = {
    paymentMethodID: string,
    customerID: string,
    supplierID: string,
};

/**
 * send a new mandate email
 */
export async function sendMandateEmail({ paymentMethodID, customerID, supplierID }: DeletePaymentMethodParams): Promise<void> {
    return axios.post(
        `${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/${paymentMethodID}/mandate-email?customer_id=${customerID}&supplier_id=${supplierID}`,
        {
            supplier_id: supplierID,
        }
    ).then((response) => response.data);
}

export type GetPaymentMethodsParams = {
    customerID: string,
    supplierID: string,
    gpoId?: string,
    token?: string,
};

/**
 * get_payment_methods in Retool
 */
export async function getPaymentMethods(params: GetPaymentMethodsParams): Promise<GetPaymentMethodsResponse> {
    const { customerID, supplierID, token, gpoId } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods`, {
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: token ?? null,
        },
        params: {
            customer_id: customerID,
            supplier_id: supplierID,
            gpo_id: gpoId,
        }
    }).then((response) => response.data);
}

export type CreateCustomerParams = {
    supplierID: string,
    body: CreateCustomerData,
    token?: string,
};

export type SendCustomersWelcomeEmailParams = {
    supplierID: string,
    body: CustomersWelcomeEmailData,
};

export type CustomersWelcomeEmailData = {
    supplier_id: string,
    customer_ids: string[],
};

export type SignUpCustomerParams = {
    supplierID: string,
    body: SignUpCustomerData,
    token?: string,
};

/**
 * create_customer in Retool
 */
export async function createCustomer(params:CreateCustomerParams): Promise<CreateCustomerResponse> {
    const { supplierID, body, token } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/customers?supplier_id=${supplierID}`, body, {
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: token ?? null,
        }

    }).then((response) => response.data);
}

/**
 * send a list of customers ids (or a supplier id if it is all customers) to send a welcome email too
 */
export async function sendCustomersWelcomeEmail(params:SendCustomersWelcomeEmailParams): Promise<void> {
    const { supplierID, body } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/welcome_email/?supplier_id=${supplierID}`, body);
}

export type SendAllCustomersBySupplierWelcomeEmailParams = {
    supplierID: string,
    body: {
        supplier_id: string,
    },
};

export async function signUpCustomer(params:SignUpCustomerParams): Promise<SignUpCustomerResponse> {
    const { supplierID, body, token } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/sign_up?supplier_id=${supplierID}`, body, {
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: token ?? null,
        }

    }).then((response) => response.data);
}

/**
 * create_transaction in Retool
 */
export async function createTransaction(supplierID: string, body: CreateTransactionData): Promise<CreateTransactionResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions?supplier_id=${supplierID}`, body).then((response) => response.data);
}

/**
 * get_customers in Retool
 */
export async function getCustomers(params:TUseGetCustomersQuery): Promise<GetCustomersResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/`, {
        params: {
            ...params,
            supplier_id: params.supplierID,
            gpo_id: params.gpoId,
            supplierID: undefined,
            gpoId: undefined
        },
    }).then((response) => response.data);
}

export type AttachPaymentMethodParams = {
    customerID: string,
    supplierID: string,
    gpoId?: string,
    body: AttachPaymentMethodData,
};

/**
 * attach_payment_method_to_customer in Retool
 */
export async function attachPaymentMethodToCustomer(params: AttachPaymentMethodParams): Promise<AttachPaymentMethodResponse> {
    const { customerID, supplierID, body, gpoId } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/${customerID}/payment_methods`, body, {
        params: {
            supplier_id: supplierID,
            gpo_id: gpoId,
        }
    }).then((response) => response.data);
}

export type SetDefaultPaymentMethodParams = {
    paymentMethodID: string,
    supplierID: string,
    body: UpdatePaymentMethodData,
};

/**
 * set_default_payment_method in Retool
 */
export async function setDefaultPaymentMethod({
    paymentMethodID,
    supplierID,
    body,
}: SetDefaultPaymentMethodParams): Promise<Record<string, never>> {
    return axios.put(`${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/${paymentMethodID}?supplier_id=${supplierID}`, body).then((response) => response.data);
}

export type SetDefaultPaymentMethodAsCustomerParams = {
    paymentMethodID: string,
    customerID: string,
    body: UpdatePaymentMethodData,
};

export async function setDefaultPaymentMethodAsCustomer({
    paymentMethodID,
    customerID,
    body,
}: SetDefaultPaymentMethodAsCustomerParams): Promise<Record<string, never>> {
    return axios.put(`${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/${paymentMethodID}`, body, {
        params: {
            customer_id: customerID
        }
    }).then((response) => response.data);
}

type UpdatePreferredPaymentMethodForSupplierData = {
    supplier_id: string,
    is_preferred?: boolean,
};

export type SetPreferredPaymentMethodForSupplierParams = {
    paymentMethodID: string,
    supplierID: string,
    body: UpdatePreferredPaymentMethodForSupplierData,
};

export async function setPreferredPaymentMethodForSupplier({
    paymentMethodID,
    supplierID,
    body,
}: SetPreferredPaymentMethodForSupplierParams): Promise<Record<string, never>> {
    return axios.patch(
        `${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/${paymentMethodID}/suppliers`,
        body,
        {
            params: {
                supplier_id: supplierID
            }
        }
    ).then((response) => response.data);
}

type CreateLinkPaymentMethodForSupplierData = {
    supplier_id: string,
    is_preferred?: boolean,
};

export type CreateLinkPaymentMethodForSupplierParams = {
    paymentMethodID: string,
    supplierID: string,
    body: CreateLinkPaymentMethodForSupplierData,
};

export async function createLinkPaymentMethodForSupplier({
    paymentMethodID,
    supplierID,
    body,
}: CreateLinkPaymentMethodForSupplierParams): Promise<Record<string, never>> {
    return axios.post(
        `${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/${paymentMethodID}/suppliers`,
        body,
        {
            params: {
                supplier_id: supplierID
            }
        }
    ).then((response) => response.data);
}

export type GetPayoutParams = {
    supplierID: string,
    gpoId?: string,
    payoutID?: string,
};

export type GetPayoutResponse = Payout;

/**
 * get_transactions in Retool (named wrong and also a duplicate)
 */
export async function getPayout(params: GetPayoutParams): Promise<GetPayoutResponse> {
    const { supplierID, payoutID, gpoId } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/payouts/${payoutID}`, {
        params: {
            supplier_id: supplierID,
            gpo_id: gpoId,
        }
    }).then((response) => response.data);
}

type RefundPaymentParams = {
    supplierID: string,
    body: RefundPaymentBody,
    gpoId?: string,
};

/**
 * refund_payment in Retool
 */
export async function refundPayment(params: RefundPaymentParams): Promise<Record<string, never>> {
    const { supplierID, body, gpoId } = params;
    return axios.post(
        `${ACCOUNTS_RECEIVABLE_API}/v1/refunds?supplier_id=${supplierID}`,
        body,
        {
            params: {
                supplier_id: supplierID,
                gpo_id: gpoId,

            }
        }
    ).then((response) => response.data);
}

export type GetBalancesParams = {
    supplier_id: string,
    gpo_id?: string,
    limit?: number,
    offset?: number,
    has_payment_method?: boolean,
    has_auto_collection?: boolean,
    customer_id?: string,
    external_id?: string,
    sort_by?: string,
    has_invoices_notifications?: string,
    terms?: number,
    name?: string,
    is_active?: boolean,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * get_balances in Retool
 */
export async function getBalances(params: GetBalancesParams): Promise<GetBalancesResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/balances`, {
        params,
    }).then((response) => response.data);
}

export type GetSupplierParams = {
    supplierID: string,
    gpoId?: string,
    customerId?: string,
};
/**
 * get_supplier in Retool
 */
export async function getSupplier(params: GetSupplierParams): Promise<GetSupplierResponse> {
    const { supplierID, gpoId, customerId } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}`, {
        params: {
            supplier_id: supplierID,
            gpo_id: gpoId,
            customer_id: customerId
        }
    }).then((response) => response.data);
}

/**
 * get AR notifications
 */
export async function getSupplierNotificationRules(params: GetSupplierParams): Promise<GetNotificationsResponse> {
    const { supplierID } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/notifications/${supplierID}`).then((response) => response.data);
}

export type GetCustomerNotificationsParams = {
    supplierID: string,
    customerID: string,
    gpoID?: string,
};

/**
 * get AR customer notifications
 */
export async function getCustomerNotificationRules(params: GetCustomerNotificationsParams): Promise<GetNotificationsResponse> {
    const { supplierID, customerID, gpoID } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/notifications/${supplierID}/${customerID}`, {
        params: {
            gpo_id: gpoID
        }
    }).then((response) => response.data);
}

export async function getSupplierCustomer(params: GetSupplierParams): Promise<GetSupplierResponse> {
    const { supplierID, customerId } = params;
    const { supplierLoginData } = useSupplierStore.getState();
    const supplierToken = supplierLoginData?.api_token;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}`, {
        params: {
            supplier_id: supplierID,
            customer_id: customerId
        },
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: supplierToken,
        }
    }).then((response) => response.data);
}

export type GetPayoutsSortBy = '-received_at' | 'received_at' | 'amount' | '-amount';
export type PayoutsType = 'paid' | 'pending' | 'in_transit' | 'canceled' | 'failed';

export type GetPayoutsParams = {
    supplier_id?: string,
    gpo_id?:string,
    limit?: number,
    offset?: number,
    status?: PayoutsType,
    sort_by?: GetPayoutsSortBy,
    amount?: number,
    received_at_start_date?: string | Date,
    received_at_end_date?: string | Date,
    supplier_ids?: string[],
};
/**
 * get_payouts in Retool
 */
export async function getPayouts(params: GetPayoutsParams): Promise<GetPayoutsResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/payouts`, { params: {
        ...params,
        supplier_ids: params?.supplier_ids?.length > 0 ? params?.supplier_ids?.join(',') : undefined,

    }, }).then((response) => response.data);
}

export type GetPayoutsScheduleParams = {
    stripeAccountID: string,
    supplierID: string,
    gpoId?: string,
};

export type GetPayoutsScheduleResponse = {
    schedule?: {
        delay_days?: number,
        interval?: string,
    },
};
/**
 *
 */
export async function getPayoutSchedule(params: GetPayoutsScheduleParams): Promise<GetPayoutsScheduleResponse> {
    const { stripeAccountID, supplierID, gpoId } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/payouts/${stripeAccountID}/schedule?`, {
        params: {
            supplier_id: supplierID,
            gpo_id: gpoId,
        }
    }).then((response) => response.data);
}

export type GetSupplierStripeExternalAccountResponse = Stripe.BankAccount | Stripe.Card | null;
/**
 *
 */
export async function getSupplierStripeExternalAccount(params: GetSupplierParams): Promise<GetSupplierStripeExternalAccountResponse> {
    const { supplierID } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/external_accounts?supplier_id=${supplierID}`).then((response) => response.data);
}

export type UploadSupplierBrandingLogoInput = {
    supplierID: string,
    image: File,
};

export type UploadSupplierBrandingLogoResponse = {
    logoUrl: string,
};

/**
 *  Upload supplier branding logo to GCS
 */
export async function uploadSupplierBrandingLogo({ supplierID, image }: UploadSupplierBrandingLogoInput): Promise<UploadSupplierBrandingLogoResponse> {
    const url: string = `${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/branding_logos`;
    const body: FormData = new FormData();
    body.append('logo', image);

    const response: AxiosResponse<UploadSupplierBrandingLogoResponse> = await axios.post(url, body)
        .catch(() => {
            throw new Error();
        });

    return response.data;
}

export type DeleteSupplierBrandingLogoInput = {
    supplierID: string,
    fileName: string,
};

/**
 * Delete supplier branding logo from GCS
 */
export async function deleteSupplierBrandingLogo({ supplierID, fileName }: DeleteSupplierBrandingLogoInput): Promise<void> {
    const url: string = `${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/branding_logos`;

    await axios.delete(url, {
        data: {
            fileName,
        },
    })
        .catch((e) => {
            throw new Error(e);
        });
}

export type GetSuppliersSortBy = 'createdAt' | '-createdAt' | 'name' | '-name' | 'email' | '-email' | 'integrationName' | '-integrationName' | undefined;
export type GetSuppliersParams = {
    gpo_id?: string,
    limit?: number,
    offset?: number,
    sort_by?: GetSuppliersSortBy,
    name?: string,
    email?: string,
};
export interface SuppliersOutput {
    id: string,
    name: string | null | undefined,
    email: string,
    integration_name: string | null,
    onboarding_link: string | null,
    created_at: string,
    updated_at: string,
    last_transaction_at: string | null,
}

export interface GetSuppliersOutput {
    suppliers: SuppliersOutput[],
    total?: number,
    limit?: number,
    offset?: number,
}

/**
 *
 */
export async function getSuppliers(params: GetSuppliersParams): Promise<GetSuppliersOutput> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers`, {
        params,
    }).then((response) => ({ ...response.data, limit: params.limit, offset: params.offset }));
}
/**
 * Represents the rate information for a supplier.
 *
 * @interface
 */
export interface PaymentRate {
    id: string,
    supplier_id: string,
    type: string,
    variable_rate: number,
    fixed_amount: number,
    fee_cap_amount: number,
}
/**
 * Represents the output of the GetSupplierPaymentRates API.
 *
 * @typedef {object} GetSupplierPaymentRatesOutput
 * @property {PaymentRate[]} rates - The array of supplier rates.
 */
export type GetSupplierPaymentRatesOutput = {
    rates:PaymentRate[],
};

/**
 * Retrieves the payment rates for a specific supplier.
 *
 * @param {string} supplierID - The ID of the supplier.
 * @returns {Promise<GetSupplierPaymentRatesOutput>} A promise that resolves to the payment rates for the supplier.
 */
export async function getSupplierPaymentRates(supplierID: string): Promise<GetSupplierPaymentRatesOutput> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/payment_rates`).then((response) => response.data);
}

type UpdateSupplierPaymentRate = Partial<PaymentRate>;

export type UpdateSupplierPaymentRatesParams = {
    supplierID: string,
    body: {
        rates: UpdateSupplierPaymentRate[],
    },
};

export type UpdateSupplierPaymentRatesOutput = {
    rates: UpdateSupplierPaymentRate[],
};

/**
 *
 */
export async function updateSupplierPaymentRates(params:UpdateSupplierPaymentRatesParams): Promise<UpdateSupplierPaymentRatesOutput> {
    const { supplierID, body } = params;
    return axios.patch(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/payment_rates`, body).then((response) => response.data);
}

export type CreateSetupIntentParams = {
    supplierID: string,
    customerID: string,
    token?: string,
};
interface CreateSetupIntentOutput {
    client_secret: string,
}

export enum Currency {
    usd = 'usd',
    cad = 'cad'
}

export async function createSetupIntent(params:CreateSetupIntentParams): Promise<CreateSetupIntentOutput> {
    const { supplierID, customerID: customer_id } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/setup_intent?supplier_id=${supplierID}`, {
        customer_id
    }, {
        params: {
            customer_id
        },
        headers: {
            [X_AR_AUTOMATION_TOKEN_HEADER]: params.token ?? null,
        }
    }).then((response) => response.data);
}

export type AddCustomerAccountParams = {
    email: string,
    password: string,
    name: string,
    customer_id: string,
    supplier_id: string,
    token?: string,
};

export type AddCustomerAccountOutput = {
    id: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    name: string | null | undefined,
};
type Account = {
    id: string,
    email: string,
    name: string,
    createdAt: Date,
    updatedAt: Date,
};
type AddCustomerAccountResponse = Account;
export async function addCustomerAccount({ customerID, body }: { customerID: string, body: { name: string, email: string, password: string } }): Promise<AddCustomerAccountResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/${customerID}/accounts`, body, {
        headers: {
            authorization: serverTokenHeader,
        },
    }).then((response) => response.data);
}

export type GetBillsParams = {
    supplier_ids?: string[],
    supplier_id?: string,
    supplier_id_filter?: string,
    customer_id?: string,
    decline_code?: string,
    due_date_end?: string | Date,
    due_date_start?: string | Date,
    external_id?: string,
    // has_auto_collection?: boolean,
    has_payment_method?: boolean,
    invoice_date_end?: string | Date,
    invoice_date_start?: string | Date,
    invoice_number?: string,
    limit?: number,
    offset?: number,
    overdue?: boolean,
    sort_by?: GetTransactionSortBy,
    status?: GetTransactionStatus,
    synced_with_cash_application?: boolean,
    terms?: number,
};
export interface GetBillsResponse {
    bills: Transaction[],
    limit: number,
    offset: number,
    total: number,
}
export async function getBills(params: GetBillsParams): Promise<GetBillsResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/bills`, {
        params: {
            ...params,
            supplier_ids: params?.supplier_ids?.length > 0 ? params?.supplier_ids?.join(',') : undefined,
        },
    }).then((response) => response.data);
}

export type PayBillsParams = {
    customerID: string,
    body: PayInvoicesData,
};

/**
 * pay_invoices in Retool
 */
export async function payBills(params: PayBillsParams): Promise<PayInvoicesResponse[]> {
    const { customerID, body } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/pay?customer_id=${customerID}`, body).then((response) => response.data);
}

export interface GetCustomerSupplierMappingsParams {
    customerID: string,
    limit?: number,
    offset?: number,
    has_payment_method?: boolean,
    has_auto_collection?: boolean,
    supplier_id?: string,
    sort_by?: string,
    name?: string,
}

export interface GetCustomerSupplierMappingsOutput {
    suppliers: {
        id: string,
        name: string,
        auto_collection: boolean,
        payment_methods: {
            is_preferred: boolean,
            id: string,
            brand: string,
            exp_month: number,
            exp_year: number,
            last4: string,
            is_primary: boolean,
            type: string,
            mandate_id: string | null,
            mandate_created_at: string | null,
        }[],
    }[],
}
/**
 *
 */
export async function getCustomerSuppliers(params: GetCustomerSupplierMappingsParams): Promise<GetCustomerSupplierMappingsOutput> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/customers/${params.customerID}/suppliers`, {
        params: {
            customer_id: params.customerID,
            ...params
        },
    }).then((response) => response.data);
}

interface ForgotPasswordParams {
    body: {
        email: string,
    },
}

export async function forgotPassword(params: ForgotPasswordParams): Promise<Record<string, never>> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/forgot_password`, params.body).then((response) => response.data);
}

interface ResetPasswordParams {
    body: {
        token: string,
        password: string,
    },
}

export async function resetPassword(params: ResetPasswordParams): Promise<Record<string, never>> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/reset_password`, params.body).then((response) => response.data);
}

export type GetGposResponse = {
    gpos: {
        id: string,
        name: string,
        logo?: string,
    }[],
};
/**
 *
 */
export async function getGpos():Promise<GetGposResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/gpos`).then((response) => response.data);
}

export type GetPaymentMethodSchemaParams = {
    paymentReceiverID: string,
    supplierID: string,
    customerID?: string,
};
export type GetPaymentMethodSchemaResponse = { paymentMethods: {
    type: string,
    name: string,
    brands?: string[],
}[], };

export async function getPaymentMethodSchema(params:GetPaymentMethodSchemaParams): Promise<GetPaymentMethodSchemaResponse> {
    const { paymentReceiverID } = params;
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/paymentReceivers/${paymentReceiverID}/adyenPaymentMethodsSchema`, {
        params: {
            supplier_id: params.supplierID,
            customer_id: params.customerID ?? undefined
        },
    }).then((response) => response.data);
}

export type CreatePaymentSourceParams = {
    body: {
        senderId: string,
        paymentReceiverId: string,
        details: any,
        returnUrl: string,

    },
    supplierID: string,
};
export type PaymentSourceStatus = {
    pending: 'pending',
    active: 'active',
    rejected: 'rejected',
};

export type PaymentSource = {
    id: string,
    senderId: string,
    externalPaymentId: string | null,
    currencyCode: string,
    status: PaymentSourceStatus,
};

export async function createPaymentSource(params: CreatePaymentSourceParams): Promise<PaymentSource> {
    const { body } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/paymentSources`, body, {
        params: {
            supplier_id: params.supplierID,

        }
    }).then((response) => response.data);
}

interface CardData {
    cardNumber: string,
    expiryMonth: string,
    expiryYear: string,
    securityCode: string,
}

interface BankData {
    type: string,
    accountRole: string,
    ownerName: string,
    accountNumber: string,
    routingNumber?: string,
    institutionNumber?: string,
    transitNumber?: string,
}

export type AttachAdyenCardPaymentMethodParams = {
    body: {
        supplierId: string,
        customerId: string,
        cardData: CardData,
        default?: boolean,
    },
};

export type AttachAdyenBankPaymentMethodParams = {
    body: {
        supplierId: string,
        customerId: string,
        bankData: BankData,
        isMandateAttestedOffline?: boolean,
        default?: boolean,
    },
};

export type AttachAdyenPaymentMethodParams = AttachAdyenCardPaymentMethodParams | AttachAdyenBankPaymentMethodParams;

export async function attachAdyenPaymentMethod(params:AttachAdyenPaymentMethodParams): Promise<AttachPaymentMethodResponse> {
    const { body } = params;
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/payment_methods/`, body, {
        params: {
            supplier_id: body.supplierId,
            customer_id: body.customerId,
        }
    }).then((response) => response.data);
}

export type VerifyPaymentReceiverParams = {
    paymentReceiverID: string,
    supplierID: string,
};

export type VerifyPaymentReceiverResponse = {
    url: string,
};

export async function verifyPaymentReceiver(params:VerifyPaymentReceiverParams):Promise<VerifyPaymentReceiverResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/paymentReceivers/${params.paymentReceiverID}/verify`, {}, {
        params: {
            supplier_id: params.supplierID,
        }
    }).then((response) => response.data);
}

export type RefreshSessionTokenParams = {
    body: {
        refresh_token: string,
    },
};

export type RefreshSessionTokenResponse = {
    api_token: string,
    expires_at: string,
};

export async function refreshSessionToken(refreshToken:string): Promise<AxiosResponse<RefreshSessionTokenResponse>> {
    const payload: RefreshSessionTokenParams = {
        body: {
            refresh_token: refreshToken,
        },
    };
    return unAuthAxiosInstance.post(`${ACCOUNTS_RECEIVABLE_API}/refresh_token`, payload.body);
}

export type GetCustomerByEmailParams = {
    supplierID: string,
    email: string,
};

/**
 *
 */
export async function getCustomerByEmail(params: GetCustomerByEmailParams): Promise<CustomerData> {
    const { supplierID, email } = params;
    return unAuthAxiosInstance.get(`${ACCOUNTS_RECEIVABLE_API}/v1/customers_by_email`, {
        params: {
            supplier_id: supplierID,
            email,
        },
        headers: {
            authorization: serverTokenHeader,
        },
    }).then((response) => response.data);
}

/**
 * Get supplier token.
 */
export async function getSupplierToken(supplierID: string): Promise<string> {
    return axios
        .get<{ secret: string }>(`${ACCOUNTS_RECEIVABLE_API}/v1/suppliers/${supplierID}/api_token?supplier_id=${supplierID}`)
        .then(({ data }) => data.secret);
}

export interface GeneratePDFForTransactionsParams {
    supplierID: string,
    body: {
        transaction_ids: string[],
    },
}

export interface GeneratePDFForTransactionsResponse {
    urls: string[],
    failed_transaction_ids: string[],
}
export async function generatePDFForTransactions(params:GeneratePDFForTransactionsParams): Promise<GeneratePDFForTransactionsResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/transactions/generate_pdf`, params.body, {
        params: {
            supplier_id: params.supplierID
        }
    }).then((response) => response.data);
}

export enum EBatchIngestionType {
    INVOICE = 'invoice',
    CUSTOMERS = 'customers',
}

interface BatchIngestionParams {
    file: File,
    ingestionType: string,
    supplierID: string,
    accountId: string,
    type: EBatchIngestionType,
}

export enum BatchIngestionResultStatus {
    Error = 'ERROR',
    MissingValues = 'MISSING_VALUES',
    Summary = 'SUMMARY',

}

export interface BatchIngestionResult {
    externalId: string,
    status:string | BatchIngestionResultStatus,
    message: string,
}

/**
 *
 */
export async function uploadInvoiceBatch(params: BatchIngestionParams): Promise<BatchIngestionResult[]> {
    const formData = new FormData();
    formData.append('import', params.file, 'import.csv');
    formData.append('ingestionType', params.ingestionType);
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/bulk/upload/${params.type}/supplier/${params.supplierID}/account/${params.accountId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).then((response) => response.data);
}

export type EmailInvoicePDFParams = {
    body: {
        supplier_id: string,
        transaction_id: string,
    },
};

export async function emailInvoicePDF(params: EmailInvoicePDFParams): Promise<void> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/email_invoice_pdf`, params.body).then((response) => response.data);
}

export type DraftInvoiceSortBy = '' | '-created_at' | 'created_at' | '-updated_at' | 'updated_at' | '-external_id' | 'external_id' | '-status' | 'status' | '-source' | 'source' | '-source_bucket' | 'source_bucket' | '-po_number' | 'po_number' | '-invoice_total' | 'invoice_total' | '-offline_credits_payment' | 'offline_credits_payment' | '-outstanding_balance' | 'outstanding_balance' | '-has_items' | 'has_items';
export enum DraftInvoiceStatus {
    Processing = 'processing',
    Draft = 'draft',
    Confirmed = 'confirmed',
    Published = 'published',
    Archived = 'archived',
}

export type GetDraftInvoicesParams = {
    id?: string,
    customer_id?: string[],
    invoice_date?: string,
    due_date?: string,
    amount?: number,
    status?: DraftInvoiceStatus[],
    sort_by?: DraftInvoiceSortBy,
    limit: number,
    offset: number,
    supplier_id: string,
    due_date_end?: string | Date,
    due_date_start?: string | Date,
    invoice_date_end?: string | Date,
    invoice_date_start?: string | Date,
    invoice_number?: string,
};

export type DraftInvoiceItem = {
    id: string,
    draft_invoice_id: string,
    external_id: string,
    item_code: string,
    description: string,
    quantity: number,
    price: number,
    total: number,
    tax_amount: number,
    created_at?: Date | string,
    created_by: string,
    updated_at?: Date | string,
    updated_by: string | null,
};

export type DraftInvoiceSourceEnum = 'batch_csv' | 'email_pdf';

export type DraftInvoiceStatusType = 'draft' | 'confirmed' | 'processing' | 'published' | 'archived';
export type DraftInvoice = {
    id: string,
    source: DraftInvoiceSourceEnum,
    source_bucket: string,
    external_id: string,
    created_at?: Date | string,
    created_by: string,
    updated_at?: Date | string,
    updated_by: string,
    status: DraftInvoiceStatusType,
    po_number: string,
    invoice_total?: number | null,
    offline_credits_payment?: number | null,
    outstanding_balance?: number | null,
    has_items?: boolean,
    ocr_json?: any,
    transaction_id?: string | null,
    customer_id?: string | null,
    supplier_id?: string | null,
    invoice_pdf_url?: string | null,
    due_date?: string | null,
    invoice_date?: string | null,
    terms?: string | null,
    platform_id?: string | null,
    invoice_number?: string | null,
    customer?: {
        businessName: string,
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
    },
    line_items?: DraftInvoiceItem[],
    attachments?: string[],
};

export type GetDraftInvoicesResponse = {
    invoices: DraftInvoice[],
    total: number,
    limit: number,
    offset: number,
};

export async function getDraftInvoices(params:GetDraftInvoicesParams): Promise<GetDraftInvoicesResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/draft_invoices`, {
        params: {
            ...params,
            // send the value as a comma separated string if it is an array
            customer_id: params?.customer_id?.length > 0 ? params?.customer_id?.join(',') : undefined,
            status: params?.status?.length > 0 ? params?.status?.join(',') : undefined,
        },
    }).then((response) => response.data);
}

export type GetDraftInvoiceIDParams = {
    draftInvoiceID: string,
    supplierID: string,
};

export async function getDraftInvoice(params:GetDraftInvoiceIDParams): Promise<DraftInvoice> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/draft_invoices/${params.draftInvoiceID}`, {
        params: {
            supplier_id: params.supplierID,
        }
    }).then((response) => response.data);
}

export type PatchDraftInvoiceLineItemData = {
    id: string,
    description: string,
    quantity: number,
    price: number,
    tax_amount: number,
    total: number,
};

export type PatchDraftInvoiceBody = Partial<{
    customer_id: DraftInvoice['customer_id'],
    invoice_date: DraftInvoice['invoice_date'],
    due_date: DraftInvoice['due_date'],
    invoice_number: DraftInvoice['invoice_number'],
    terms: DraftInvoice['terms'],
    invoice_total: DraftInvoice['invoice_total'],
    offline_credits_payment: DraftInvoice['offline_credits_payment'],
    outstanding_balance: DraftInvoice['outstanding_balance'],
    line_items: PatchDraftInvoiceLineItemData[],
    status: DraftInvoiceStatusType,
}>;

export type UpdateDraftInvoiceParams = {
    draftInvoiceID: string,
    supplierID: string,
    body: PatchDraftInvoiceBody,
};

export async function patchDraftInvoice(params: UpdateDraftInvoiceParams): Promise<DraftInvoice> {
    return axios.patch(
        `${ACCOUNTS_RECEIVABLE_API}/v1/draft_invoices/${params.draftInvoiceID}`,
        params.body,
        {
            params: {
                supplier_id: params.supplierID,
            }
        }
    ).then((response) => response.data);
}

export type PublishDraftInvoiceParams = {
    draftInvoiceID: string,
    supplierID: string,
};

export type PublishDraftInvoiceResponse = {
    id?: string,
    missing_fields?: string[],
};

export async function publishDraftInvoice(params:PublishDraftInvoiceParams): Promise<PublishDraftInvoiceResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/draft_invoices/${params.draftInvoiceID}/publish`, {}, {
        params: {
            supplier_id: params.supplierID,
        }
    }).then((response) => response.data);
}

export async function republishDraftInvoice(params:PublishDraftInvoiceParams): Promise<PublishDraftInvoiceResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/draft_invoices/${params.draftInvoiceID}/republish`, {}, {
        params: {
            supplier_id: params.supplierID,
        }
    }).then((response) => response.data);
}

export type DeleteDraftInvoiceParams = {
    draftInvoiceID: string,
    supplierID: string,
};
export async function deleteDraftInvoice(params:DeleteDraftInvoiceParams): Promise<void> {
    return axios.delete(`${ACCOUNTS_RECEIVABLE_API}/v1/draft_invoices/${params.draftInvoiceID}`, {
        params: {
            supplier_id: params.supplierID,
        }
    }).then((response) => response.data);
}

export type SignUrlsParams = {
    urls: string[],
    supplierID?: string,
    customerID?: string,
};

export type SignUrlsResponse = {
    signed_urls: string[],
};

export async function signUrls(params: SignUrlsParams): Promise<SignUrlsResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/sign_urls`, {
        urls: params.urls,

    }, {
        params: {
            supplier_id: params.supplierID,
            customer_id: params.customerID,
        }
    }).then((response) => response.data);
}

// Bills

export type ApBillSortBy = '' | '-created_at' | 'created_at' | '-updated_at' | 'updated_at' | '-external_id' | 'external_id' | '-status' | 'status' | '-source' | 'source' | '-source_bucket' | 'source_bucket' | '-po_number' | 'po_number' | '-bill_total' | 'bill_total' | '-offline_credits_payment' | 'offline_credits_payment' | '-outstanding_balance' | 'outstanding_balance' | '-has_items' | 'has_items';
export enum ApBillStatus {
    Processing = 'processing',
    Draft = 'draft',
    Confirmed = 'confirmed',
    Published = 'published',
    Archived = 'archived',
}

export type GetApBillsParams = {
    id?: string,
    customer_id: string[],
    bill_date?: string,
    due_date?: string,
    amount?: number,
    status?: ApBillStatus[],
    sort_by?: ApBillSortBy,
    limit: number,
    offset: number,
    due_date_end?: string | Date,
    due_date_start?: string | Date,
    bill_date_end?: string | Date,
    bill_date_start?: string | Date,
    bill_number?: string,
};

export type ApBillItem = {
    id: string,
    item_code: string,
    description: string,
    quantity: number,
    price: number,
    total: number,
    tax_amount?: number,
    created_at?: Date | string,
    created_by?: string,
    updated_at?: Date | string,
    updated_by?: string | null,
    tax_percentage: number,
    unit_name?: string,
    discount?: number,
    unit_price?: number,
};

export type ApBillSourceEnum = 'batch_csv' | 'email_pdf';

export type ApBillStatusType = 'draft' | 'confirmed' | 'processing' | 'published' | 'archived';
export type ApBill = {
    id: string,
    source: ApBillSourceEnum,
    source_bucket: string,
    external_id: string,
    created_at?: Date | string,
    created_by: string,
    updated_at?: Date | string,
    updated_by: string,
    status: ApBillStatusType,
    po_number: string,
    bill_total?: number | null,
    offline_credits_payment?: number | null,
    outstanding_balance?: number | null,
    has_items?: boolean,
    ocr_json?: any,
    transaction_id?: string | null,
    customer_id?: string | null,
    supplier_id?: string | null,
    bill_pdf_url?: string | null,
    due_date?: string | null,
    bill_date?: string | null,
    terms?: string | null,
    platform_id?: string | null,
    bill_number?: string | null,
    customer?: {
        businessName: string,
        line1?: string | null,
        line2?: string | null,
        city?: string | null,
        state?: string | null,
        postalCode?: string | null,
        country?: string | null,
    },
    line_items?: ApBillItem[],
    attachments?: string[],
};

export type GetApBillsResponse = {
    bills: ApBill[],
    total: number,
    limit: number,
    offset: number,
};

export async function getApBills(params:GetApBillsParams): Promise<GetApBillsResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/ap_bills`, {
        params: {
            ...params,
            // send the value as a comma separated string if it is an array
            customer_id: params?.customer_id?.length > 0 ? params?.customer_id?.join(',') : undefined,
            status: params?.status?.length > 0 ? params?.status?.join(',') : undefined,
        },
    }).then((response) => response.data);
}

export type GetApBillIDParams = {
    apBillID: string,
    customerID: string,
};

export async function getApBill(params:GetApBillIDParams): Promise<ApBill> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/ap_bills/${params.apBillID}`, {
        params: {
            customer_id: params.customerID,
        }
    }).then((response) => response.data);
}

export type PatchApBillLineItemData = {
    id: string,
    description: string,
    quantity: number,
    price: number,
    tax_amount: number,
    total: number,
    tax_percentage: number,
    unit_name?: string,
    discount?: number,
    unit_price?: number,
    item_code: string,
};

export type PatchApBillBody = Partial<{
    customer_id: ApBill['customer_id'],
    bill_date: ApBill['bill_date'],
    due_date: ApBill['due_date'],
    bill_number: ApBill['bill_number'],
    terms: ApBill['terms'],
    bill_total: ApBill['bill_total'],
    offline_credits_payment: ApBill['offline_credits_payment'],
    outstanding_balance: ApBill['outstanding_balance'],
    line_items: PatchApBillLineItemData[],
    status: ApBillStatusType,
}>;

export type UpdateApBillParams = {
    apBillID: string,
    customerID: string,
    body: PatchApBillBody,
};

export async function patchApBill(params: UpdateApBillParams): Promise<ApBill> {
    return axios.patch(
        `${ACCOUNTS_RECEIVABLE_API}/v1/ap_bills/${params.apBillID}`,
        params.body,
        {
            params: {
                customer_id: params.customerID,
            }
        }
    ).then((response) => response.data);
}

export type PublishApBillParams = {
    apBillID: string,
    customerID: string,
};

export type PublishApBillResponse = {
    id?: string,
    missing_fields?: string[],
};

export async function publishApBill(params:PublishApBillParams): Promise<PublishApBillResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/ap_bills/${params.apBillID}/publish`, {}, {
        params: {
            customer_id: params.customerID,
        }
    }).then((response) => response.data);
}

export async function republishApBill(params:PublishApBillParams): Promise<PublishApBillResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/ap_bills/${params.apBillID}/republish`, {}, {
        params: {
            customer_id: params.customerID,
        }
    }).then((response) => response.data);
}

export type DeleteApBillParams = {
    apBillID: string,
    customerID: string,
};
export async function deleteApBill(params:DeleteApBillParams): Promise<void> {
    return axios.delete(`${ACCOUNTS_RECEIVABLE_API}/v1/ap_bills/${params.apBillID}`, {
        params: {
            customer_id: params.customerID,
        }
    }).then((response) => response.data);
}

export type UploadApBillParams = {
    supplierID?: string,
    customerID: string,
    files: File[],
};

export type UploadApBillResponse = {
    status: string,
};

export async function uploadApBill(params: UploadApBillParams): Promise<UploadApBillResponse> {
    const formData = new FormData();
    params.files.forEach((file) => {
        formData.append('files', file);
    });
    formData.append('customer_id', params.customerID);
    formData.append('supplier_id', params.supplierID ?? '');
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/pdf/bills/upload`, formData, {
        params: {
            customer_id: params.customerID,
            supplier_id: params.supplierID,
        },
    }).then((response) => response.data);
}

// Mandate network calls

export type GetMandateParams = {
    mandateID: string,
};

export enum MandateStatus {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    EXPIRED = 'expired',
}

export interface Mandate {
    id: string,
    status: MandateStatus,
    email: string,
    emailSentEventId: string,
    paymentMethodId: string,
    createdAt: string,
    updatedAt: string,
    acceptedAt: string | null,
    acceptedBy: string | null,
    mandateDetails: {
        emailData: {
            supplierName: string | null,
            supplierEmail: string | null,
            customerEmail: string | null,
            supplierLogo: string | null,

        },
    },
    paymentMethod: {
        id: string,
        stripePaymentMethodId: string,
        paymentSourceId: string | null,
        createdAt: string,
        updatedAt: string,
        deletedAt: string | null,
        brand: string,
        last4: string,
        expYear: number,
        expMonth: number,
        isPrimary: boolean,
        customerId: string,
        notifyExpireAt: string | null,
        type: string,
        mandateId: string,
        mandateCreatedAt: string,
        mandateOnBehalfOf: string,
        routingNumber: string,
        require_3ds: boolean,
    },
}

export type GetMandateResponse = Mandate;

export async function getMandate(params: GetMandateParams): Promise<GetMandateResponse> {
    return axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/mandate/${params.mandateID}`).then((response) => response.data);
}

export enum MandateResponse {
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
}

export type RespondMandateParams = {
    mandateID: string,
    body: {
        response: MandateResponse,
        responder_name: string,
        responder_job_title: string,
    },
};

export type RespondMandateResponse = Mandate;

export async function respondMandate(params: RespondMandateParams): Promise<RespondMandateResponse> {
    return axios.post(`${ACCOUNTS_RECEIVABLE_API}/v1/mandate/${params.mandateID}/response`, params.body).then((response) => response.data);
}

export enum PaymentMethodType {
    CARD = 'card',
    ACSS_DEBIT = 'acss_debit',
    US_BANK_ACCOUNT = 'us_bank_account'
}

export enum AccountRole {
    CHECKING = 'checking',
    SAVINGS = 'savings',
}

export type GetAdyenPaymentMethodTypeParams = {
    paymentReceiverId: string,
    customerId?: string,
    supplierId?: string,
};

type CorePaymentTypeStatus = 'active' | 'inactive';

export enum PaymentTypes {
    Ach = 'ach',
    Acss = 'acss',
    Amex = 'amex',
    Mc = 'mc',
    Visa = 'visa'
}

export type CorePaymentType = {
    id: string,
    corePaymentReceiverId: string,
    method: {
        type: PaymentTypes,
    },
    externalPaymentMethodId: string,
    fee: any, // Consider making this more specific based on your JSON structure
    currencies: Currency[],
    status: CorePaymentTypeStatus,
    surchargeMax: number | null,
    createdAt: Date,
    updatedAt: Date,
    deletedAt: Date | null,
};

export type GetAdyenPaymentMethodTypeResponse = {
    paymentTypes:CorePaymentType[],
};

export const getAdyenPaymentMethodType = async (params: GetAdyenPaymentMethodTypeParams): Promise<GetAdyenPaymentMethodTypeResponse> => axios.get(`${ACCOUNTS_RECEIVABLE_API}/v1/paymentReceivers/${params.paymentReceiverId}/paymentTypes`, {
    params: {
        customer_id: params.customerId,
        supplier_id: params.supplierId,
    }
}).then((response) => response.data);
