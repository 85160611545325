import { EstimatedSurcharge, GetPaymentMethodResponse, GetPaymentMethodsResponse, PaymentMethodType, Transaction } from '@ar/network/AccountsReceivable.network';
import BigNumber from 'bignumber.js';
import { getCurrencyFormatFromCents } from '@v2/utils/CurrencyUtils';

export type PaymentMethodByCustomer = Record<string, GetPaymentMethodsResponse>;
export type InvoicesByCustomer = Record<string, Transaction[]>;
export const FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY = 'FETCH_BULK_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY';

/**
 * Groups invoices by customer ID.
 *
 * @param invoices - An array of transactions representing invoices.
 * @returns An object where the keys are customer IDs and the values are arrays of transactions.
 */
export const groupInvoicesByCustomerID = (invoices: Transaction[] = []): InvoicesByCustomer => invoices?.reduce((acc, inv) => {
    const customerID = inv.customer_id;
    if (!acc[customerID]) {
        acc[customerID] = [];
    }
    acc[customerID].push(inv);
    return acc;
}, {}) ?? {};

/**
 * Checks if all invoices are from the same customer.
 *
 * @param invoices - An array of transactions representing invoices.
 * @returns A boolean indicating whether all invoices are from the same customer.
 */
export const areAllInvoicesFromSameCustomer = (invoices: Transaction[] = []): boolean => {
    if (!invoices?.length) {
        return false;
    }
    const { customer_id: firstCustomerID } = invoices[0];
    return invoices.every((invoice) => invoice.customer_id === firstCustomerID);
};

/**
 * Sums up all surcharges for a combined invoice for a given payment method.
 *
 * @param paymentMethod - A payment method used to charge invoices
 * @param estimatedSurcharges - A 2D array of estimated surcharges for all invoices for each supported payment method type
 */
export const getBulkEstimatedSurcharge = (paymentMethod: GetPaymentMethodResponse, estimatedSurcharges: EstimatedSurcharge[][]): BigNumber => {
    let surchargeAmount = new BigNumber(0);
    if (paymentMethod && estimatedSurcharges?.length) {
        const estimatedSurcharge = estimatedSurcharges.flat().filter((surcharge) => surcharge?.paymentType?.type?.toLowerCase() === paymentMethod.brand?.toLowerCase());
        const estimatedSurchargeAmount = estimatedSurcharge?.reduce((acc, surcharge) => acc + surcharge.estimatedSurcharge, 0);
        surchargeAmount = surchargeAmount.plus(estimatedSurchargeAmount).dividedBy(100);
    }
    return surchargeAmount;
};

/**
 * Returns the estimated surcharge for an invoice for a given payment method.
 *
 * @param paymentMethod - A payment method used to charge invoices
 * @param estimatedSurcharges - An array of estimated surcharges for each supported payment method type
 */
export const getEstimatedSurcharge = (paymentMethod: GetPaymentMethodResponse, estimatedSurcharges: EstimatedSurcharge[]): BigNumber => {
    let surchargeAmount = new BigNumber(0);
    if (paymentMethod?.type === PaymentMethodType.CARD && estimatedSurcharges?.length) {
        const estimatedSurcharge = estimatedSurcharges.find((surcharge) => surcharge?.paymentType?.type?.toLowerCase() === paymentMethod.brand?.toLowerCase());
        const estimatedSurchargeAmount = estimatedSurcharge?.estimatedSurcharge;
        surchargeAmount = surchargeAmount.plus(estimatedSurchargeAmount).dividedBy(100);
    }
    return surchargeAmount;
};

/**
 * Formats estimated surcharges to be displayed.
 *
 * @param surchargeAmount - A BigNumber storing the surcharge amount
 */
export const formatSurchargeAmount = (surchargeAmount: BigNumber): string => {
    const positivePrefix = '+';
    const formattedAsCurrency = getCurrencyFormatFromCents(surchargeAmount);
    return surchargeAmount.isGreaterThan(0) ? (positivePrefix + formattedAsCurrency) : formattedAsCurrency;
};
