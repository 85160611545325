import { Button, Input, Separator, Typography } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import ExampleCheck from '@ar/assets/images/example-ach-check.png';
import CheckIcon from '@icons/check-icon.svg';
import { encryptWithPem } from '@/utils/encryptUtils';
import { AdyenACHMandateModal, EncryptedBankData } from './AdyenACHMandateModal/AdyenACHMandateModal';
import { CreatePaymentMethodSteps } from '../CreateAdyenPaymentMethodConstants';

export interface CreateACHBankAccountProps {
    handleClose: () => void,
    customerID: string,
    supplierID: string,
    currentStep: CreatePaymentMethodSteps,
    setCurrentStep: (step: CreatePaymentMethodSteps) => void,
}

export type BankAccountDetails = {
    ownerName: string,
    routingNumber: string,
    accountNumber: string,
    confirmAccountNumber: string,
};

const NUM_FIELDS = 4;

/**
 * ACH routing numbers have 9 digits and the last digit is a checksum.
 * The following condition must hold:
 * (3(d1 + d4 + d7) + 7(d2 + d5 + d8) + (d3 + d6 + d9)) mod 10 = 0
 * https://stripe.com/en-ca/resources/more/ach-routing-numbers-explained
 * https://en.wikipedia.org/wiki/ABA_routing_transit_number
 */
const isValidRoutingNumber = (routingNumber: string): boolean => routingNumber.length === 9
    && routingNumber.split('').map(Number).reduce((sum, digit, index) => {
        const weights = [3, 7, 1];
        const weightIndex = index % 3;
        return sum + digit * weights[weightIndex];
    }, 0) % 10 === 0;

export const validationSchema = z.object({
    ownerName: z.string().nonempty('Name of account holder is required').min(2, 'Must be full name'),
    routingNumber: z.string().nonempty('Routing number is required').length(9, 'Must be 9 digits'),
    accountNumber: z.string().nonempty('Account number is required').min(7, 'Must be 7-17 digits').max(17, 'Must be 7-17 digits'),
    confirmAccountNumber: z.string().nonempty('Confirm account number is required')
}).refine((data) => data.confirmAccountNumber === data.accountNumber, {
    message: 'Account numbers must match',
    path: ['confirmAccountNumber'],
}).refine((data) => isValidRoutingNumber(data.routingNumber), {
    message: 'Routing number is invalid. Please verify and re-enter',
    path: ['routingNumber'],
});

export const CreateACHBankAccount = ({ handleClose, customerID, supplierID, currentStep, setCurrentStep }: CreateACHBankAccountProps): JSX.Element => {
    const [bankData, setBankData] = useState<EncryptedBankData>(null);

    const emptyBankAccount = {
        ownerName: '',
        routingNumber: '',
        accountNumber: '',
        confirmAccountNumber: ''
    };

    const { handleSubmit, control, reset } = useForm<BankAccountDetails>({
        resolver: zodResolver(validationSchema),
        defaultValues: emptyBankAccount,
        mode: 'onBlur',
    });

    const { errors, touchedFields, isDirty } = useFormState({
        control,
    });

    const resetForm = (): void => {
        reset();
    };

    const handleOnClose = ():void => {
        resetForm();
        handleClose();
    };

    const encrypt = async (newFormBankAccount: BankAccountDetails): Promise<{ encryptedOwnerName: string, encryptedRoutingNumber: string, encryptedAccountNumber: string }> => {
        const { ownerName, routingNumber, accountNumber } = newFormBankAccount;
        const encryptedOwnerName = await encryptWithPem(ownerName);
        const encryptedRoutingNumber = await encryptWithPem(routingNumber);
        const encryptedAccountNumber = await encryptWithPem(accountNumber);
        return { encryptedOwnerName, encryptedRoutingNumber, encryptedAccountNumber };
    };

    const onSubmit = async (newFormBankAccount: BankAccountDetails): Promise<void> => {
        setBankData(await encrypt(newFormBankAccount) as EncryptedBankData);
        setCurrentStep(CreatePaymentMethodSteps.ACH_MANDATE);
        resetForm();
    };

    const hasErrors = Object.keys(errors).some((key) => errors[key]);
    const allFieldsTouched = Object.keys(touchedFields).length === NUM_FIELDS && Object.values(touchedFields).every((value) => value);
    const isSubmitDisabled = hasErrors || !allFieldsTouched || !isDirty;

    return <> {currentStep === CreatePaymentMethodSteps.ADD_ACH
        && <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8 py-6 min-w-0">
                <div className="flex-col flex gap-4">
                    <Typography weight="font-regular" variant="BASE" desktopSize="lg:text-3.5" className="m-0">Enter your customer’s bank account information.</Typography>
                    <Controller
                        name="ownerName"
                        control={control}
                        render={({ field }) => <Input
                            name="ownerName"
                            id="ownerName"
                            label="Name of account holder"
                            type="TEXT"
                            inputProps={{
                                placeholder: 'Must match name on account',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.ownerName}
                            invalidMessage={errors.ownerName?.message}
                            labelIcon={touchedFields.ownerName && !errors.ownerName ? <CheckIcon data-testid="owner-name-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="routingNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="routingNumber"
                            id="routingNumber"
                            label="Routing number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '9 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.routingNumber}
                            invalidMessage={errors.routingNumber?.message}
                            labelIcon={touchedFields.routingNumber && !errors.routingNumber ? <CheckIcon data-testid="routing-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="accountNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="accountNumber"
                            id="accountNumber"
                            label="Account number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '7-17 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.accountNumber}
                            invalidMessage={errors.accountNumber?.message}
                            labelIcon={touchedFields.accountNumber && !errors.accountNumber ? <CheckIcon data-testid="account-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="confirmAccountNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="confirmAccountNumber"
                            id="confirmAccountNumber"
                            label="Confirm account number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '7-17 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.confirmAccountNumber}
                            invalidMessage={errors.confirmAccountNumber?.message}
                            labelIcon={touchedFields.confirmAccountNumber && !errors.confirmAccountNumber ? <CheckIcon data-testid="confirm-account-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <img
                        className="example-check-image"
                        src={ExampleCheck} />
                </div>
            </div>

            <Separator />
            <div className="pt-4 px-6 pb-1 flex justify-end gap-3">
                <Button variant="TERTIARY_FILLED"
                    as={'span'}
                    className="cursor-pointer"
                    size="SMALL"
                    onClick={handleOnClose}>
                    <Typography as="span" weight="font-medium">
                            Cancel
                    </Typography>
                </Button>
                <Button variant="SECONDARY"
                    size="SMALL"
                    disabled={isSubmitDisabled}
                    type="submit">
                    <div className="flex gap-1">
                        <Typography as="span" weight="font-medium">
                                Save & next
                        </Typography>
                        <ArrowRightIcon className="w-4 h-4 mt-0.5" />
                    </div>
                </Button>
            </div>
        </form>}
    {currentStep === CreatePaymentMethodSteps.ACH_MANDATE
    && <AdyenACHMandateModal
        handleClose={() => {
            setCurrentStep(CreatePaymentMethodSteps.SELECT_PAYMENT_METHOD);
            handleClose();
        }}
        bankData={bankData}
        customerID={customerID}
        supplierID={supplierID}/>
    }
    </>;
};
