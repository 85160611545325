import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { CreateAdyenCardPaymentMethod } from '@/ar/components/shared/modals/CreateAdyenPaymentMethod/CreateAdyenCardPaymentMethod/CreateAdyenCardPaymentMethod';
import { CreateAdyenPaymentMethodModal as CreatePaymentMethodModal } from '@/ar/components/shared/modals/CreateAdyenPaymentMethod/CreatePaymentMethodModal';
import { CreateStripeCardPaymentMethod } from '@/ar/components/shared/modals/CreateStripePaymentMethod/CreateStripeCardPaymentMethod';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { ActivePaymentMethods } from '@/utils/constants';

export interface AddNewPaymentMethodProps {
    isOpen?: boolean,
    handleClose: () => void,
    customerID: string,
    subTitle?: string,
    supplierID?: string,
}

export const CreatePaymentMethod = ({
    isOpen,
    handleClose,
    customerID,
    subTitle,
    supplierID
}: AddNewPaymentMethodProps): JSX.Element => {
    const flags = useFlags<FeatureFlags>();
    const isAdyenBankDebitEnabled = flags?.arPaymentProvider?.us_bank_account === ActivePaymentMethods.adyen || flags?.arPaymentProvider?.acss_debit === ActivePaymentMethods.adyen;
    const enableAddCardPaymentMethodsWithAdyen = flags?.arPaymentProvider?.card === ActivePaymentMethods.adyen;
    const enableAddCardPaymentMethodsWithStripe = flags?.arPaymentProvider?.card === ActivePaymentMethods.stripe;
    const showStripePaymentMethodModal = enableAddCardPaymentMethodsWithStripe && isOpen;
    const showAdyenPaymentMethodModal = enableAddCardPaymentMethodsWithAdyen && !isAdyenBankDebitEnabled && isOpen;
    const showGenericPaymentMethodModal = isAdyenBankDebitEnabled && isOpen;

    return <>
        {showStripePaymentMethodModal
            && <CreateStripeCardPaymentMethod isOpen={isOpen}
                handleClose={handleClose}
                supplierID={supplierID}
                subTitle={subTitle}
                customerID={customerID}/>
        }
        {showAdyenPaymentMethodModal
            && <CreateAdyenCardPaymentMethod isOpen={isOpen}
                handleClose={handleClose}
                subTitle={subTitle}
                supplierID={supplierID}
                customerID={customerID}/>
        }
        {showGenericPaymentMethodModal // Planning for this to be the de facto payment method modal once we're fully on Adyen for cards, ACH, and EFT
            && <CreatePaymentMethodModal isOpen={isOpen}
                handleClose={handleClose}
                customerID={customerID}
                subTitle={subTitle}/>
        }
    </>;
};
