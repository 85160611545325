import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';

import { GetPaymentMethodResponse, payBills } from '@ar/network/AccountsReceivable.network';
import { FETCH_BILLS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { useBillStore } from '@ar/stores/BillsStore';
import { getGpoId } from '@v2/utils/GPOUtils';
import { ChargeModalCustomerTile } from '@v2/components/Shared/ChargeModalCustomerTile/ChargeModalCustomerTile';
import { getCurrencyFormatFromCents } from '@v2/utils/CurrencyUtils';
import BigNumber from 'bignumber.js';
import { isPendingMandatePaymentMethod } from '@v2/utils/PaymentMethodUtils';
import { centsToDollars } from '@/utils/Formatters';
import { AmplitudeCharge, AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';
import { useGetCustomerPaymentMethods } from '@/ar/hooks/queries/CustomerQueries.hook';
import { useRefetchIncompletePaymentMethods } from '@/ar/hooks/useRefetchPaymentMethods';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';

export const BillManualChargeModal : React.FC = () => {
    const { modals, setModal } = useBillStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const currentTransaction = modals.transaction;
    const [installmentDate, setInstallmentDate] = useState<string>('');
    const [amount, setAmount] = useState<number>(currentTransaction?.outstanding_amount ?? 0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<GetPaymentMethodResponse>(null);
    const supplierID = supplierLoginData?.supplier_id ?? currentTransaction?.supplier_id;
    const { data: paymentMethodsResponse, isLoading: isLoadingPaymentMethods } = useGetCustomerPaymentMethods({
        supplierID,
        customerID: currentTransaction?.customer_id,
        gpoId: getGpoId(supplierLoginData),
    });
    const [shouldDisablePayButton, setShouldDisablePayButton] = useState<boolean>(false);
    const [estimatedTotal, setEstimatedTotal] = useState<BigNumber>(new BigNumber(amount));

    const { trackAmplitudeEvent } = useAmplitude();
    const isModalOpen = modals.manualCharge;
    const hasIncompletePaymentMethodSelected = selectedPaymentMethod && !selectedPaymentMethod?.last4;
    const formIsInvalid = ():boolean => !selectedPaymentMethod || !amount || amount > currentTransaction?.outstanding_amount;
    const handleOnClose = ():void => {
        setSelectedPaymentMethod(null);
        setAmount(0);
        setEstimatedTotal(new BigNumber(0));
        queryClient.invalidateQueries([FETCH_BILLS_QUERY_KEY]);
        setModal('manualCharge', false, null);
        setInstallmentDate('');
    };

    useRefetchIncompletePaymentMethods({
        paymentMethods: paymentMethodsResponse?.payment_methods ?? [],
        onComplete: () => {
            setSelectedPaymentMethod(paymentMethodsResponse?.payment_methods?.find((paymentMethod) => paymentMethod?.id === selectedPaymentMethod?.id) ?? null);
        }
    });

    const [autoPayMethod] = useAutoPayMethod(supplierID, supplierLoginData.isCustomer, paymentMethodsResponse);

    // if there are payment methods and there is no selected payment method, select the default payment method
    useEffect(() => {
        if (paymentMethodsResponse?.payment_methods?.length > 0 && !selectedPaymentMethod) {
            setSelectedPaymentMethod(autoPayMethod ?? paymentMethodsResponse?.payment_methods[0]);
        }
    }, [paymentMethodsResponse, selectedPaymentMethod]);

    useEffect(() => {
        if (autoPayMethod) {
            setSelectedPaymentMethod(autoPayMethod);
        }
    }, [autoPayMethod?.id]);

    useEffect(() => {
        if (isModalOpen) {
            trackAmplitudeEvent(AmplitudeEventActions.pageChargeViewed, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
        }
    }, [isModalOpen]);
    const payBillsMutation = useMutation(payBills, {
        onSuccess: (_data) => {
            const paidSuccessful = _data[0].charged;
            let toastMessage;
            if (paidSuccessful) {
                toastMessage = `${getCurrencyFormatFromCents(estimatedTotal)} paid for Bill #${currentTransaction?.invoice_number}`;
            } else {
                toastMessage = 'Payment has failed';
            }
            toast.show({
                message: toastMessage,
            });
            trackAmplitudeEvent(AmplitudeEventActions.charged, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
            handleOnClose();
        },
        onError: (error) => {
            toast.show({
                message: 'Something went wrong. Please try again.',
            });
            console.info(error);
        },
    });
    const handleCharge = ():void => {
        if (formIsInvalid()) {
            return;
        }
        trackAmplitudeEvent(AmplitudeEventActions.pushCharge, { type: AmplitudeCharge.individualCharge, transaction: currentTransaction?.id, countInvoices: 1 });
        payBillsMutation.mutate({
            customerID: supplierLoginData?.customer_id,
            body: {
                is_lumped: false,
                payment_method_id: selectedPaymentMethod?.id,
                transaction_ids: [currentTransaction?.id],
                installments_schedule: installmentDate ? [installmentDate] : null
            },
        });
    };

    useEffect(() => {
        if (currentTransaction) {
            setAmount(centsToDollars(currentTransaction?.outstanding_amount));
        }
    }, [currentTransaction]);

    const isPayButtonDisabled = payBillsMutation.isLoading || hasIncompletePaymentMethodSelected || isPendingMandatePaymentMethod(selectedPaymentMethod) || shouldDisablePayButton;
    const getPayButtonText = (): string => (isPayButtonDisabled ? 'Pay now' : `Pay ${getCurrencyFormatFromCents(estimatedTotal)} now`);

    if (!currentTransaction || !isModalOpen) return null;
    return <Modal isOpen={isModalOpen}
        title={<Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm charge(s)</Typography>}
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[480px]">
        <div className="p-6">
            <ChargeModalCustomerTile
                isCustomerPortal
                transactions={[currentTransaction]}
                paymentMethods={paymentMethodsResponse.payment_methods}
                isLoadingPaymentMethods={isLoadingPaymentMethods}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                setShouldDisablePayButton={setShouldDisablePayButton}
                setEstimatedTotal={setEstimatedTotal} />
        </div>
        <Separator/>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={handleOnClose}>
                <Typography as="span" weight="font-medium">
                    Cancel
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                disabled={isPayButtonDisabled}
                loading={payBillsMutation.isLoading}
                size="SMALL"
                onClick={handleCharge}>
                <Typography as="span" weight="font-medium">
                    {payBillsMutation.isLoading ? <Loading/> : getPayButtonText()}
                </Typography>
            </Button>
        </div>
    </Modal>;
};
