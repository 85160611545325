import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Loading, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { useSupplierStore } from '@ar/stores/SupplierStore';

import { GetPaymentMethodResponse, payBills } from '@ar/network/AccountsReceivable.network';
import { FETCH_BILLS_QUERY_KEY } from '@ar/hooks/queries/InvoicesQueries.hook';
import { areAllInvoicesFromSameCustomer } from '@ar/components/Invoices/Modals/BulkManualChargeModal/BulkManualChargeModalConstants';
import { isPendingMandatePaymentMethod } from '@v2/utils/PaymentMethodUtils';
import { useBillStore } from '@ar/stores/BillsStore';
import { getGpoId } from '@v2/utils/GPOUtils';
import { ChargeModalCustomerTile } from '@v2/components/Shared/ChargeModalCustomerTile/ChargeModalCustomerTile';
import { getCurrencyFormatFromCents } from '@v2/utils/CurrencyUtils';
import BigNumber from 'bignumber.js';
import { centsToDollars } from '@/utils/Formatters';
import { AmplitudeCharge, AmplitudeEventActions, useAmplitude } from '@/containers/app/AmplitudeContext';
import { useGetCustomerPaymentMethods } from '@/ar/hooks/queries/CustomerQueries.hook';
import { useRefetchIncompletePaymentMethods } from '@/ar/hooks/useRefetchPaymentMethods';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';

export const BillsBulkManualChargeModal : React.FC = () => {
    const { modals, setModal, getReadyToBeChargedInvoices, clearSelectedInvoices } = useBillStore();
    const { supplierLoginData } = useSupplierStore();
    const queryClient = useQueryClient();
    const bills = getReadyToBeChargedInvoices();// check if all invoices to be charged have the same customer id
    const isSameCustomerID = areAllInvoicesFromSameCustomer(bills);

    // get the total amount to be charged
    const totalOutstandingAmount = bills.reduce((acc, invoice) => acc + invoice.outstanding_amount, 0);

    // state variables
    const [amount, setAmount] = useState(totalOutstandingAmount ?? 0);
    const [isLump, setIsLump] = useState(true);
    const [installmentDate, setInstallmentDate] = useState<string>('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<GetPaymentMethodResponse>(null);
    const supplierID = supplierLoginData?.supplier_id ?? bills[0]?.supplier_id;
    const { data: paymentMethodsResponse, isLoading: isLoadingPaymentMethods } = useGetCustomerPaymentMethods({
        supplierID,
        customerID: bills[0]?.customer_id,
        gpoId: getGpoId(supplierLoginData),
    });
    const [shouldDisablePayButton, setShouldDisablePayButton] = useState<boolean>(false);
    const [estimatedTotal, setEstimatedTotal] = useState<BigNumber>(new BigNumber(amount));

    const hasIncompletePaymentMethod = selectedPaymentMethod && !selectedPaymentMethod?.last4;
    const { trackAmplitudeEvent } = useAmplitude();

    useRefetchIncompletePaymentMethods({
        paymentMethods: paymentMethodsResponse?.payment_methods ?? [],
        onComplete: () => {
            setSelectedPaymentMethod(paymentMethodsResponse?.payment_methods?.find((paymentMethod) => paymentMethod?.id === selectedPaymentMethod?.id) ?? null);
        }
    });

    const [autoPayMethod] = useAutoPayMethod(supplierID, supplierLoginData.isCustomer, paymentMethodsResponse);

    // if there are payment methods and there is no selected payment method, select the default payment method
    useEffect(() => {
        if (paymentMethodsResponse?.payment_methods?.length > 0 && !selectedPaymentMethod) {
            setSelectedPaymentMethod(autoPayMethod ?? paymentMethodsResponse?.payment_methods[0]);
        }
    }, [paymentMethodsResponse, selectedPaymentMethod]);

    useEffect(() => {
        if (autoPayMethod) {
            setSelectedPaymentMethod(autoPayMethod);
        }
    }, [autoPayMethod?.id]);

    // close the modal method cleaning up the state and invalidating the query
    const handleOnClose = ():void => {
        setSelectedPaymentMethod(null);
        setAmount(0);
        setEstimatedTotal(new BigNumber(0));
        setIsLump(true);
        setModal('bulkManualCharge', false, null);
        setInstallmentDate('');
        queryClient.invalidateQueries([FETCH_BILLS_QUERY_KEY]);
    };

    // mutation to charge the invoices
    const payBillsMutation = useMutation(payBills, {
        onSuccess: (_data, variables) => {
            const paidSuccessful = _data.filter((data) => data.charged === true);
            const paidSuccessfulCount = paidSuccessful.length;
            const numberOfInvoicesCharged = variables.body.transaction_ids.length;
            let toastMessage;
            if (paidSuccessfulCount === numberOfInvoicesCharged) {
            // All bills paid successfully
                const messageText = `${getCurrencyFormatFromCents(estimatedTotal)} paid for ${numberOfInvoicesCharged > 1 ? `${numberOfInvoicesCharged} bills` : `${numberOfInvoicesCharged} bill`}`;
                toastMessage = isSameCustomerID ? messageText : `${numberOfInvoicesCharged > 1 ? `${numberOfInvoicesCharged} bills` : `${numberOfInvoicesCharged} bill`} paid`;
            } else if (paidSuccessfulCount === 0) {
            // No bills paid successfully
                toastMessage = `${numberOfInvoicesCharged > 1 ? 'All payments have failed' : 'Payment has failed'}`;
            } else if ((paidSuccessfulCount > 0) && (paidSuccessfulCount !== numberOfInvoicesCharged)) {
            // Only some bills paid successfully
                const textNumberFailed = `${numberOfInvoicesCharged - paidSuccessfulCount > 1 ? `${numberOfInvoicesCharged - paidSuccessfulCount} payments have failed` : `${numberOfInvoicesCharged - paidSuccessfulCount} payment has failed`}`;
                const textNumberSuccesful = `${paidSuccessfulCount > 1 ? `${paidSuccessfulCount} payments have succeeded` : `${paidSuccessfulCount} payment has succeeded`}`;
                toastMessage = `${textNumberFailed}, ${textNumberSuccesful}`;
            }
            toast.show({
                message: toastMessage
            });
            trackAmplitudeEvent(AmplitudeEventActions.charged, { type: AmplitudeCharge.bulkCharge, countInvoices: payBills.length });
            handleOnClose();
            // after the invoices have been charged, clear the selected invoices
            clearSelectedInvoices();
        },
        onError: (error) => {
            toast.show({
                message: 'Something went wrong while charging the bills',
            });
            console.error(error);
        },
    });

    const handleCharge = ():void => {
        // get the ids of the invoices to be charged
        const invoicesIDs = bills.map((invoice) => invoice.id);
        // if there is more than one invoice, use the user selection to determine if it should be lumped else don't lump
        const isLumped = bills.length > 1 ? isLump : false;
        trackAmplitudeEvent(AmplitudeEventActions.pushCharge, { type: AmplitudeCharge.bulkCharge, countInvoices: bills.length });
        payBillsMutation.mutate({
            customerID: supplierLoginData?.customer_id,
            body: {
                is_lumped: isLumped,
                payment_method_id: selectedPaymentMethod?.id,
                transaction_ids: invoicesIDs,
                installments_schedule: installmentDate ? [installmentDate] : null
            },
        });
    };
    useEffect(() => {
        if (modals.bulkManualCharge) {
            trackAmplitudeEvent(AmplitudeEventActions.pageChargeViewed, { type: AmplitudeCharge.bulkCharge, countInvoices: payBills.length });
        }
    }, [modals.bulkManualCharge]);
    // whenever the invoices selected change, update the amount to be charged
    useEffect(() => {
        setAmount(centsToDollars(totalOutstandingAmount));
    }, [bills]);

    if (!modals.bulkManualCharge) return null;

    const isPayButtonDisabled = payBillsMutation.isLoading || hasIncompletePaymentMethod || isPendingMandatePaymentMethod(selectedPaymentMethod) || shouldDisablePayButton;
    const getPayButtonText = (): string => (isPayButtonDisabled ? 'Pay now' : `Pay ${getCurrencyFormatFromCents(estimatedTotal)} now`);

    return <Modal isOpen={modals.bulkManualCharge}
        title={<Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Confirm charge(s)</Typography>}
        headerPadding="mt-0 mb-5"
        titleSeparatorDesktop={true}
        close={handleOnClose}
        modalSize="SMALL"
        desktopModalWidth="lg:w-[480px]">
        <div className="p-6">
            <ChargeModalCustomerTile
                isCustomerPortal
                transactions={bills}
                paymentMethods={paymentMethodsResponse.payment_methods}
                isLoadingPaymentMethods={isLoadingPaymentMethods}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                setShouldDisablePayButton={setShouldDisablePayButton}
                setEstimatedTotal={setEstimatedTotal}
                isSameCustomerID={isSameCustomerID}
                isLumpedPayment={isLump}
                setIsLumpedPayment={setIsLump} />
        </div>
        <Separator/>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={handleOnClose}>
                <Typography as="span" weight="font-medium">
                    Cancel
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                disabled={isPayButtonDisabled}
                loading={payBillsMutation.isLoading}
                size="SMALL"
                onClick={handleCharge}>
                <Typography as="span" weight="font-medium">
                    {payBillsMutation.isLoading ? <Loading/> : getPayButtonText()}
                </Typography>
            </Button>
        </div>
    </Modal>;
};
