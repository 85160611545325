import { Button, Checkbox, Loading, RadioTile, Tooltip, Typography } from '@notch-ordering/ui-components';
import React, { useEffect, useState } from 'react';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import { getCurrencyFormatFromCents } from '@v2/utils/CurrencyUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BigNumber from 'bignumber.js';
import { isSurchargeEnabled } from '@v2/utils/Surcharge';
import { GetPaymentMethodResponse, PaymentMethodType, Transaction } from '@/ar/network/AccountsReceivable.network';
import PaymentMethodBadge from '@/ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { useBillStore } from '@/ar/stores/BillsStore';
import { useInvoiceStore } from '@/ar/stores/InvoicesStore';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { LoadingPaymentMethodBanner } from '@/ar/components/Invoices/Modals/BulkManualChargeModal/LoadingPaymentMethodBanner';
import { getSurchargeDisplay, getTotalEstimatedSurcharge, isPaymentMethodBrandKnown } from './ChargeModalCustomerTileConstants';

export interface ChargeModalCustomerTileProps {
    isCustomerPortal: boolean,
    transactions: Transaction[],
    paymentMethods: GetPaymentMethodResponse[],
    isLoadingPaymentMethods: boolean,
    selectedPaymentMethod: GetPaymentMethodResponse,
    setSelectedPaymentMethod: (paymentMethod: GetPaymentMethodResponse) => void,
    setShouldDisablePayButton: (toggle: boolean) => void,
    setEstimatedTotal: (total: BigNumber) => void,
    isSameCustomerID?: boolean,
    isLumpedPayment?: boolean,
    setIsLumpedPayment?: (toggle: boolean) => void,
}

export const ChargeModalCustomerTile: React.FC<ChargeModalCustomerTileProps> = ({ isCustomerPortal, transactions, paymentMethods, isLoadingPaymentMethods, selectedPaymentMethod, setSelectedPaymentMethod, setShouldDisablePayButton, setEstimatedTotal, isSameCustomerID = true, isLumpedPayment = false, setIsLumpedPayment = () => {} }) => {
    const flags = useFlags();
    const { supplierLoginData } = useSupplierStore();
    const isUsingSurcharges = isSurchargeEnabled(transactions ?? [], flags, supplierLoginData?.isSupplier, supplierLoginData?.gpo);
    const [showSurchargeTooltip, setShowSurchargeTooltip] = useState<boolean>(false);
    const [currentSurcharge, setCurrentSurcharge] = useState<BigNumber>(new BigNumber(0));
    const baseTotal = transactions.reduce((acc, transaction) => acc.plus(transaction.outstanding_amount), new BigNumber(0));
    const [currentEstimatedTotal, setCurrentEstimatedTotal] = useState<BigNumber>(baseTotal);
    const { setModal } = isCustomerPortal ? useBillStore() : useInvoiceStore();
    const hasIncompletePaymentMethodSelected = selectedPaymentMethod && !selectedPaymentMethod?.last4;

    useEffect(() => {
        if (isUsingSurcharges) {
            setShouldDisablePayButton(!isPaymentMethodBrandKnown(selectedPaymentMethod, transactions));
        }
    }, [selectedPaymentMethod]);

    useEffect(() => {
        if (isUsingSurcharges) {
            setCurrentSurcharge(getTotalEstimatedSurcharge(selectedPaymentMethod, transactions));
        }
    }, [selectedPaymentMethod]);

    useEffect(() => {
        if (isUsingSurcharges) {
            setCurrentEstimatedTotal(baseTotal.plus(currentSurcharge));
        } else {
            setCurrentEstimatedTotal(baseTotal);
        }
    }, [currentSurcharge, isUsingSurcharges]);

    useEffect(() => {
        setEstimatedTotal(currentEstimatedTotal);
    }, [currentEstimatedTotal]);

    const getPaymentMethodsDisplay = (): JSX.Element => <>
        <div className="flex flex-col items-center justify-between gap-2">
            {paymentMethods?.length > 0 ? paymentMethods.map((paymentMethod) => <RadioTile
                className="w-full border rounded items-center pt-1"
                key={paymentMethod.id}
                value={paymentMethod.id}
                onChange={(): void => {
                    setSelectedPaymentMethod(paymentMethod);
                }}
                selected={paymentMethod === selectedPaymentMethod}>
                <div className="w-full flex flex-row justify-between items-center mx-2">
                    <PaymentMethodBadge
                        className="items-center my-0"
                        key={paymentMethod.id}
                        brand={paymentMethod.brand}
                        last4={paymentMethod.last4}
                        exp_month={paymentMethod.exp_month}
                        exp_year={paymentMethod.exp_year}
                        isDefault={paymentMethod.is_primary}
                        mandate_status={paymentMethod.mandate_status} />
                    {isUsingSurcharges && getSurchargeDisplay(paymentMethod, transactions)}
                </div>
            </RadioTile>)
                : <Typography variant="LG-1" weight="font-regular" className="text-gray-600 text-center">No payment methods saved for this customer.</Typography>}
        </div>
        {hasIncompletePaymentMethodSelected && selectedPaymentMethod.type === PaymentMethodType.CARD && <div className="mt-4"><LoadingPaymentMethodBanner/></div>}
        {isSameCustomerID && <Button
            onClick={() => setModal('addPaymentMethod', true, transactions[0])}
            size="SMALL"
            variant="TEXT"
            className="text-1 p-0 pt-2">
                Add payment method
        </Button>}
        {transactions.length > 1 && <div className="pt-6">
            <Checkbox
                className="items-start [&>*:first-child]:shrink-0"
                label={isSameCustomerID ? 'Lump invoice payments into 1 charge' : 'Lump invoice payments to 1 charge per customer'}
                value={'isLumpedPayment'}
                checked={isLumpedPayment}
                onChange={(): void => {
                    setIsLumpedPayment(!isLumpedPayment);
                }}/>
        </div>}
        { /* TODO: CORE-495 - <SchedulePaymentInput installmentDate={installmentDate} setInstallmentDate={setInstallmentDate}/> */ }
    </>;

    return <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between">
            <Typography variant="LG-2" weight="font-medium" className="mb-2">{transactions[0]?.business_name}</Typography>
            <Typography variant="LG-2" weight="font-medium" className="mb-2">{getCurrencyFormatFromCents(currentEstimatedTotal)}</Typography>
        </div>
        <div className="flex flex-row justify-between mb-3.5">
            <Typography variant="LG-1" weight="font-regular" className="text-gray-650">{transactions.length} invoice{transactions.length > 1 ? 's' : ''}</Typography>
            <Typography variant="LG-1" weight="font-regular" className="text-gray-650">{getCurrencyFormatFromCents(baseTotal)}</Typography>
        </div>
        <div className="flex flex-row justify-between">
            <Typography variant="BASE" weight="font-medium" className="text-gray-650 mb-2">Select payment method</Typography>
            <div className="flex flex-row gap-1">
                {isUsingSurcharges && <>
                    <Typography variant="BASE" weight="font-medium" className="text-gray-650 mb-2">Surcharge</Typography>
                    <Tooltip show={showSurchargeTooltip}
                        tooltipClassName="w-52 py-2.5 px-2.5"
                        showArrow={false}
                        placement="top-end"
                        trigger={
                            <div onMouseEnter={(): void => setShowSurchargeTooltip(true)} onMouseLeave={(): void => setShowSurchargeTooltip(false)}>
                                <InfoOutlineIcon className="w-3 h-3 mb-px text-gray-600"/>
                            </div>}>
                        <div>
                            <span>A surcharge may be applied when payments are made using a credit card.</span>
                        </div>
                    </Tooltip>
                </>}
            </div>
        </div>
        <div className="mb-6 h-9.5">
            {isLoadingPaymentMethods ? <Loading isDark /> : getPaymentMethodsDisplay()}
        </div>
    </div>;
};
