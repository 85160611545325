import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Popover, toast, Typography, PopoverItem } from '@notch-ordering/ui-components';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { GetCustomerResponse, setDefaultPaymentMethod, deletePaymentMethod, GetPaymentMethodResponse, sendMandateEmail } from '@ar/network/AccountsReceivable.network';
import { ConfirmModal, ConfirmModalProps } from '@ar/components/shared/ConfirmModal';
import { FETCH_CUSTOMER_QUERY_KEY,
    FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import { FETCH_BALANCES_QUERY_KEY } from '@ar/hooks/queries/BalanceQueries.hook';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { FilterLabel } from '@ar/components/shared/FilterLabel/FilterLabel';
import { normalizeCustomer } from '@ar/components/CustomerDetails/AutoPayAndCardSettings/helpers/normalizeCustomer';
import { AxiosError } from 'axios';
import { queryClient } from '@/containers/app/Root';
import PaymentMethodBadge from '../../../shared/PaymentMethodBadge/PaymentMethodBadge';
import { drawerInsideClassName } from '../../CustomerGeneralPopup/CustomerGeneralPopup';
import { useAutoPayMethod } from '@/ar/hooks/queries/AutoPayMethod.hook';

interface Props {
    paymentMethod: GetPaymentMethodResponse,
    hasOnlyOnePaymentMethod: boolean,
}

export const PaymentMethod = ({ paymentMethod, hasOnlyOnePaymentMethod }: Props): JSX.Element => {
    const { customer, customerID, supplierID, paymentMethods, refetchPayments, isCustomer, } = useContext(CustomerDetailsContext);
    const [defaultMethod, setDefaultMethod] = useState<GetPaymentMethodResponse>(null);
    const [autoPayMethod] = useAutoPayMethod(supplierID, isCustomer, paymentMethods);

    const setDefaultPaymentMethodMutation = useMutation(
        setDefaultPaymentMethod,
    );

    const defaultCustomerValues = normalizeCustomer(customer);
    const { reset } = useForm<GetCustomerResponse>({
        defaultValues: defaultCustomerValues,
    });

    const onSubmit = (): void => {
        if (defaultMethod?.id) {
            setDefaultPaymentMethodMutation.mutate({ paymentMethodID: defaultMethod?.id, supplierID, body: { customer_id: customerID, is_primary: true } }, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY, customerID, supplierID]);
                    toast.show({
                        message: 'Default payment method successfully updated',
                        icon: <CheckIcon />,
                        showClose: false,
                    });
                    refetchPayments();
                },
                onError: () => {
                    toast.show({
                        message: 'Error while updating default payment method',
                        showClose: false,
                    });
                },
            });
        }
    };
    const remainingPaymentMethods = paymentMethods?.payment_methods.filter((method) => !method.is_primary);

    const paymentMethodOptions = (isShowCheck: boolean = true): PopoverItem[] => remainingPaymentMethods.map((method) => ({
        label: <FilterLabel
            label={<PaymentMethodBadge key={method.id} brand={method.brand} last4={method.last4} />}
            isChecked={isShowCheck && method.id === defaultMethod?.id}/>,
        onClick: (): void => {
            setDefaultMethod(method);
        },
    }));

    React.useEffect(() => {
        if (!isEmpty(customer)) {
            reset(normalizeCustomer(customer));
        }
    }, [customer]);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const deleteMethodMutation = useMutation(deletePaymentMethod, {
        onSuccess: () => {
            setIsDeleteConfirmOpen(false);
            toast.show({
                message: 'Payment method deleted',
                icon: <CheckIcon/>,
            });
            queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY, customerID, supplierID]);
            queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY]);
            queryClient.invalidateQueries([FETCH_BALANCES_QUERY_KEY]);
        },
        onError: () => {
            toast.show({
                message: 'Failed to delete payment method, please refresh the page and try again.',
            });
        },
    });

    const sendMandateEmailMutation = useMutation(sendMandateEmail, {
        onSuccess: () => {
            toast.show({
                message: 'Debit agreement sent',
                icon: <CheckIcon/>,
            });
            queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
        },
        onError: (response) => {
            const { data } = (response as AxiosError).response;
            const { error } = data as { error: string };
            toast.show({
                message: `Error sending mandate email: ${error}`,
            });
        },
    });

    const handleClickDelete = ():void => {
        setIsDeleteConfirmOpen(true);
        setDefaultMethod(remainingPaymentMethods[0]);
    };

    const handleCancel = ():void => {
        setIsDeleteConfirmOpen(false);
        if (paymentMethod.is_primary) {
            reset();
        }
    };

    const handleClickSendMandate = ():void => {
        sendMandateEmailMutation.mutate({ paymentMethodID: paymentMethod.id, customerID, supplierID });
    };

    const handleAcceptDelete = async (): Promise<void> => {
        if (paymentMethod.is_primary) {
            onSubmit();
        }
        await deleteMethodMutation.mutateAsync({ paymentMethodID: paymentMethod.id, customerID, supplierID });
    };

    const deletionWarningModalTitle = "Deleting customer's last payment method";
    const deletionWarningModalLabel = 'Confirm';
    const deletionWarningModalPrompt = "This is the customer's final payment method. Its deletion may disrupt their future transactions. Consider advising them to retain it for uninterrupted operations.";
    const deletionConfirmationModalTitle = 'Confirm deletion of payment method';
    const deletionConfirmationModalLabel = 'Yes, remove';
    const deletionConfirmationModalPrompt = 'By deleting this payment method, it will no longer be available for future payments. Please confirm your decision.';
    const deletionPrimaryModalTitle = 'Delete payment method';
    const deletionPrimaryModalLabel = 'Confirm';
    const deletionPrimaryModalPrompt = <>
        <p>You are trying to delete a default payment method, which may be used for Autopay.</p>
        <p>Please set a new card as your default payment method.</p>
        {!!defaultMethod?.id && <div className="pb-11">
            <Typography className="text-gray-700" weight="font-medium mb-3">
                New default card
            </Typography>
            <Popover
                className="max-h-[98px] overflow-auto text-base min-w-[332px]"
                placement="bottom-start"
                parentDivClassName="w-full"
                items={paymentMethodOptions()}
                button={<Button
                    as="div"
                    type="button"
                    variant="TERTIARY_OUTLINED"
                    size="SMALL"
                    className="cursor-pointer px-2.5 w-full"
                    stopPropagation={false}>
                    <div className="flex items-center justify-between">
                        <PaymentMethodBadge
                            key={defaultMethod.id}
                            brand={defaultMethod.brand}
                            className="gap-1"
                            last4={defaultMethod.last4} />
                        <ChevronDownIcon className="w-4 h-4 text-gray-600"/>
                    </div>
                </Button>}/>
        </div>}
    </>;

    const getModalProps = (): Partial<ConfirmModalProps> => {
        if (hasOnlyOnePaymentMethod) {
            return {
                confirmLabel: deletionWarningModalLabel,
                title: deletionWarningModalTitle,
                prompt: deletionWarningModalPrompt,
            };
        }

        if (paymentMethod.is_primary) {
            return {
                confirmLabel: deletionPrimaryModalLabel,
                title: deletionPrimaryModalTitle,
                prompt: deletionPrimaryModalPrompt,
            };
        }

        return {
            confirmLabel: deletionConfirmationModalLabel,
            title: deletionConfirmationModalTitle,
            prompt: deletionConfirmationModalPrompt,
        };
    };
    const isNotBankAccount = paymentMethod.type !== 'us_bank_account' && paymentMethod.type !== 'acss_debit';
    const notAbleToSendMandateEmail = !paymentMethod.mandate_status || paymentMethod.mandate_status === 'accepted' || isNotBankAccount || paymentMethod.payment_source_id === null;

    return (
        <>
            <div className="my-4 flex justify-between">
                <PaymentMethodBadge
                    key={paymentMethod.id}
                    brand={paymentMethod.brand}
                    last4={paymentMethod.last4}
                    exp_month={paymentMethod.exp_month}
                    exp_year={paymentMethod.exp_year}
                    isAutoPay={autoPayMethod?.id === paymentMethod?.id}
                    autoCollection={customer?.auto_collection}
                    mandate_status={paymentMethod.mandate_status} />
                {paymentMethod.last4 && <Popover
                    className="w-60"
                    button={
                        <Button
                            variant="LINK"
                            className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                            size="SMALL"
                            onClick={() => trackCurrentPageTitle(TrackingEvents.moreButtonClicked)}
                            stopPropagation={false}>
                            <div className={'lg:w-4 lg:h-4 w-5 h-5'} data-testid="payment-method-menu">
                                <EllipsisIcon className="h-4 w-4" />
                            </div>
                        </Button>
                    }
                    items={
                        [
                            {
                                label: <div className="flex">
                                    <Typography className="flex flex-nowrap gap-3 items-start text-grey-700 m-0">Send debit agreement</Typography>
                                </div>,
                                onClick: handleClickSendMandate,
                                hidden: notAbleToSendMandateEmail
                            },
                            {
                                label: <div className="flex">
                                    <Typography className="flex flex-nowrap gap-3 items-start  m-0 text-red-500">Remove</Typography>
                                </div>,
                                onClick: handleClickDelete,
                            },

                        ]
                    }/>}
                <ConfirmModal
                    isOpen={isDeleteConfirmOpen}
                    onConfirm={handleAcceptDelete}
                    isLoading={deleteMethodMutation.isLoading}
                    confirmLabel={getModalProps().confirmLabel}
                    onClose={handleCancel}
                    title={getModalProps().title}
                    prompt={getModalProps().prompt}
                    className={drawerInsideClassName}
                    confirmVariant="DESTRUCTIVE" />
            </div>
            <div className="w-full h-px bg-gray-200" />
        </>);
};
