import React, { useContext, useState } from 'react';
import { Button, Input, NONE_SELECTED, Select, Separator, toast, Tooltip, Typography } from '@notch-ordering/ui-components';
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form';
import { CustomerDetailsContext } from '@ar/pages/CustomerDetailsWrapperPage/CustomerDetailsContext';
import { isEmpty } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { GetCustomerResponse, updateCustomer, UpdateCustomerData } from '@ar/network/AccountsReceivable.network';
import { FETCH_CUSTOMER_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import CheckIcon from '@icons/check-icon.svg';
import { zodResolver } from '@hookform/resolvers/zod';
import { validationSchema } from '@ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralTab/CustomerGeneralTab.validationSchema';
import AddIcon from '@icons/add-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { AxiosError } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InputPhone } from '@v2/components/Shared/InputPhone';
import InfoIcon from '@icons/info-icon.svg';
import { queryClient } from '@/containers/app/Root';
import { useDocumentTitle } from '@/shared/hooks/useDocumentTitle';
import { useSupplierStore } from '@/ar/stores/SupplierStore';
import { InputAddressAutocomplete } from '@/components/shared/InputAddressAutocomplete';
import { extractAddressFromPlace } from '@/utils/AddressUtils';
/**
 * The AR Customer General Tab
 *
 * @returns JSX Element
 */

type CustomerContactFormState = GetCustomerResponse & {
    // this is an additional field for the form state, since react-hook-form doesn't support array of strings only
    ccEmails: {
        email: string,
    }[],
};

const mapCCEmails = (emails: string[]): { email: string }[] => emails.map((email) => ({ email }));

export const CustomerGeneralTab = (): JSX.Element => {
    useDocumentTitle('Customer General - Notch');

    const { customer, isCustomerLoading, customerID, supplierID, handleCloseGeneralPopup, refetchCustomer } = useContext(CustomerDetailsContext);
    const { supplier } = useSupplierStore();
    const allowEditCustomer = supplier?.allow_edit_customer ?? false;
    const flags = useFlags();

    const [showActiveStatusTooltip, setShowActiveStatusTooltip] = useState(false);

    const updateCustomerMutation = useMutation(
        updateCustomer,
    );
    const isLoading = isCustomerLoading || updateCustomerMutation.isLoading;

    const { handleSubmit, control, reset, getValues, setError, setValue } = useForm<CustomerContactFormState>({
        resolver: zodResolver(validationSchema),
        defaultValues: { ...customer, ccEmails: mapCCEmails(customer?.cc_emails ?? []) },
        mode: 'onSubmit',
    });
    const hasIsActiveFilterEnabled = flags?.customerIsActiveFilterActiveUi;
    const { errors, isSubmitted } = useFormState({
        control,
    });

    const resetCustomer = (): void => {
        reset({
            ...customer,
            ccEmails: mapCCEmails(customer?.cc_emails ?? []),
        });
    };

    const { fields, append, remove } = useFieldArray<CustomerContactFormState>({
        control,
        name: 'ccEmails',
    });
    const handlePlaceSelected = (place: google.maps.places.PlaceResult) => {
        const address = extractAddressFromPlace(place);
        setValue('address.line1', address.line1);
        setValue('address.line2', address.line2);
        setValue('address.city', address.city);
        setValue('address.state', address.state);
        setValue('address.postal_code', address.postal_code);
        setValue('address.country', address.country);
    };

    const foundIndexesDuplicateEmail = (defaultEmail: string, listEmails: string[]) => {
        const lowerCaseSet = new Set();
        const defaultEmailLowerCase = defaultEmail.toLowerCase();
        const result = { defaultDuplicate: [], ccDuplicate: [] };
        listEmails.forEach((value, index) => {
            const lowerCaseValue = value.toLowerCase();
            if (lowerCaseValue === defaultEmailLowerCase) {
                result.defaultDuplicate.push(index);
            } else if (lowerCaseSet.has(lowerCaseValue)) {
                result.ccDuplicate.push(index);
            } else {
                lowerCaseSet.add(lowerCaseValue);
            }
        });
        return result;
    };

    const onSubmit = (): void => {
        const { ccEmails, email, name, address, terms, external_id: externalId, is_active: isActive, phone } = getValues();

        const additionalEmails = ccEmails.map((currentEmail) => currentEmail.email);

        const duplicateEmails = foundIndexesDuplicateEmail(email, additionalEmails);
        if (duplicateEmails.defaultDuplicate.length) {
            duplicateEmails.defaultDuplicate.forEach((index) => setError(`ccEmails.${index}.email`, { type: 'manual', message: `The email address ${email} is already set as the default` }));
        }
        if (duplicateEmails.ccDuplicate.length) {
            duplicateEmails.ccDuplicate.forEach((index) => setError(`ccEmails.${index}.email`, { type: 'manual', message: 'The email you are trying to add is already included in the list.' }));
        }
        if (duplicateEmails.defaultDuplicate.length || duplicateEmails.ccDuplicate.length) {
            return;
        }

        let updatedCustomer: UpdateCustomerData = {
            business_name: customer.name,
            email: customer.email,
            auto_collection: customer.auto_collection,
            weekly_statement_email: customer.weekly_statement_email,
            charge_invoice_when: customer.charge_invoice_when,
            charge_invoice_on_weekday: customer.charge_invoice_on_weekday,
            charge_invoice_on_monthday: customer.charge_invoice_on_monthday,
            invoice_notifications: customer.invoice_notifications,
            line1: customer.address.line1,
            line2: customer.address.line2,
            city: customer.address.city,
            postal_code: customer.address.postal_code,
            state: customer.address.state,
            country: customer.address.country,
            cc_emails: additionalEmails,
            is_active: hasIsActiveFilterEnabled ? isActive : customer.is_active,

        };
        if (allowEditCustomer) {
            updatedCustomer = {
                ...updatedCustomer,
                email,
                terms: Number(terms),
                external_id: externalId,
                business_name: name,
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                postal_code: address.postal_code,
                state: address.state,
                country: address.country,
                phone
            };
        }

        updateCustomerMutation.mutate({ customerID, supplierID, ...updatedCustomer }, {
            onSuccess: async () => {
                await queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY, customerID]);
                toast.show({
                    message: 'Successfully updated',
                    icon: <CheckIcon />,
                    showClose: false,
                });
                refetchCustomer();
            },
            onError: (error:AxiosError) => {
                const duplicationErrorMsg = 'Unique constraint failed on the fields: (`external_id`,`supplier_id`)';
                let message = 'Error while updating information';
                if ((error.response as { data: { error: string } }).data?.error && (error.response as { data: { error: string } }).data.error.includes(duplicationErrorMsg)) {
                    message = 'The ERP Customer ID is already in use. Please enter a different one.';
                }

                toast.show({
                    message,
                    showClose: false,
                });
            },
        });
    };

    const handleClickCancel = (): void => {
        resetCustomer();
        handleCloseGeneralPopup();
    };

    React.useEffect(() => {
        if (!isEmpty(customer)) {
            resetCustomer();
        }
    }, [customer]);

    return (
        <form className="pt-8 grid customer-details" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-10 flex flex-col gap-3">
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <Input
                        label="Name"
                        variant="SMALL"
                        disabled={!allowEditCustomer}
                        required
                        inputProps={{
                            ...field,
                        }}/>}/>

                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => <Input
                        label="Email"
                        variant="SMALL"
                        disabled={!allowEditCustomer}
                        required
                        inputProps={{
                            ...field,
                        }}/>}/>

                {fields.map((field, index) => (
                    <div key={field.id}>
                        <div className="flex gap-3 items-center">
                            <Controller
                                name={ `ccEmails.${index}.email`}
                                control={control}
                                render={({ field: f }) => <Input
                                    variant="SMALL"
                                    required
                                    isInvalid={isSubmitted && Boolean(errors?.ccEmails?.[index]?.email?.message)}
                                    invalidMessage={errors?.ccEmails?.[index]?.email?.message}
                                    value={f.value}
                                    inputProps={{
                                        ...f,
                                    }}/>}/>
                            <TrashIcon className="w-4 h-4 flex-shrink-0 cursor-pointer text-gray-600"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    remove(index);
                                }}/>
                        </div>
                    </div>
                ))}
                <button
                    className="appearance-none"
                    onClick={(e): void => {
                        e.preventDefault();
                        e.stopPropagation();
                        append({ email: '' });
                    }}>
                    <Typography className="flex items-center font-medium cursor-pointer gap-2" >
                        <AddIcon className="text-teal-500 w-4 h-4" />
                        <span className="text-teal-500">Add another email</span>
                    </Typography>
                </button>

                <div className="flex items-center gap-3">
                    <Controller
                        name="terms"
                        control={control}
                        render={({ field }) => <Input
                            label={'Payment Terms'}
                            variant="SMALL"
                            disabled={!allowEditCustomer}
                            isInvalid={isSubmitted && Boolean(errors?.terms)}
                            invalidMessage={errors?.terms?.message}
                            required
                            name="terms"
                            inputProps={{
                                ...field,
                            }}/>
                        }/>
                    <Controller
                        name="external_id"
                        control={control}
                        render={({ field }) => <Input
                            label="ERP Customer ID"
                            variant="SMALL"
                            required
                            disabled={!allowEditCustomer}
                            isInvalid={isSubmitted && Boolean(errors?.external_id)}
                            invalidMessage={errors?.external_id?.message}
                            inputProps={{
                                ...field,
                            }}/>}/>
                </div>

            </div>
            <Separator variant="small" className="my-6" />
            <div className="px-10 flex flex-col gap-3">
                <Typography variant="LG-2" className="mb-3">Billing address</Typography>
                <div className="flex">
                    <div className="w-3/4 mr-3">
                        <Controller
                            name="address.line1"
                            control={control}
                            render={({ field }) => (allowEditCustomer ? <InputAddressAutocomplete
                                variant="SMALL"
                                className="w-full"
                                isInvalid={!!errors?.address?.line1}
                                label="Street address"
                                value={field.value}
                                onChange={(e): void => {
                                    field.onChange(e);
                                }}
                                googleAutocompleteInputProps={{
                                    // this will disable the enter key from submitting the form while selecting an address
                                    onKeyDown: (e) :void => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    },
                                }}
                                onPlaceSelected={handlePlaceSelected}
                                // isInvalid={formErrors.address}
                                searchTypes={['address']}
                                invalidMessage={'Street address is required'}
                                required/>
                                : <Input
                                    label="Street address"
                                    variant="SMALL"
                                    disabled={!allowEditCustomer}
                                    required
                                    inputProps={{
                                        ...field,
                                    }}/>)

                            }/>
                    </div>
                    <div className="w-1/4">
                        <Controller
                            name="address.line2"
                            control={control}
                            render={({ field }) => <Input
                                label="Unit"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>
                <div className=" flex">
                    <div className="w-1/2 mr-3">
                        <Controller
                            name="address.city"
                            control={control}
                            render={({ field }) => <Input
                                label="City"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div className="w-1/2">
                        <Controller
                            name="address.state"
                            control={control}
                            render={({ field }) => <Input
                                label="Province"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>
                <div className=" flex">
                    <div className="w-1/2 mr-3">
                        <Controller
                            name="address.country"
                            control={control}
                            render={({ field }) => <Input
                                label="Country"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                    <div className="w-1/2">
                        <Controller
                            name="address.postal_code"
                            control={control}
                            render={({ field }) => <Input
                                label="Postal Code"
                                variant="SMALL"
                                disabled={!allowEditCustomer}
                                required
                                inputProps={{
                                    ...field,
                                }}/>}/>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => <InputPhone
                            required
                            label="Phone number"
                            value={field.value}
                            variant="SMALL"
                            disabled={!allowEditCustomer}
                            onChange={(e) => {
                                field.onChange(e.value);
                            }}/>}/>
                </div>
            </div>
            <Separator variant="small" className="mt-6" />
            {hasIsActiveFilterEnabled && <>
                <div className="px-10 py-6">
                    <div className=" mb-6">
                        <Typography className="font-medium" aria-label="Customer Active Status">
                        Status
                        </Typography>
                        <div>
                            <Controller
                                name="is_active"
                                control={control}
                                render={({ field }) => <div className="flex items-center gap-3">
                                    <Select
                                        label="Set status"
                                        required
                                        isInvalid={isSubmitted && Boolean(errors?.is_active)}
                                        invalidMessage={errors?.is_active?.message}
                                        labelIcon={ <Tooltip
                                            show={showActiveStatusTooltip}
                                            trigger={<div onMouseEnter={(): void => setShowActiveStatusTooltip(true)} onMouseLeave={(): void => setShowActiveStatusTooltip(false)}>
                                                <InfoIcon className="w-4 h-4 text-gray-600" />
                                            </div>}
                                            placement="top-end"
                                            showArrow={false}
                                            tooltipClassName="py-1.5 px-2 rounded-md"
                                            className="text-left">
                                            <Typography className="font-medium mg-0">Choose customer status: Active or Inactive</Typography>
                                        </Tooltip>}
                                        options={[{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]}
                                        value={`${field.value}`}
                                        onChange={(e) => {
                                            if (e.target.value !== NONE_SELECTED) {
                                                field.onChange(e.target.value === 'true');
                                            }
                                        }}
                                        placeholder="Select"
                                        variant="SMALL"/>
                                </div>}/>
                        </div>
                    </div>

                </div>
                <Separator variant="small" className="mt-6" />
            </>}

            <div className="px-10 py-6 flex justify-between">
                <Button className="mr-3 cursor-pointer"
                    as="div"
                    onClick={handleClickCancel}
                    disabled={!allowEditCustomer}
                    variant="TERTIARY_FILLED"
                    size="MEDIUM"
                    minWidth="w-auto">
                      Cancel
                </Button>
                <Button
                    loading={isLoading}
                    variant={'SECONDARY'}
                    size="MEDIUM"
                    minWidth="w-auto"
                    type="submit">
                      Save
                </Button>
            </div>

        </form>
    );
};
