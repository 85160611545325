import ExampleCheck from '@ar/assets/images/example-eft-check.svg';
import { zodResolver } from '@hookform/resolvers/zod';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { Button, Input, Separator, Typography } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { z } from 'zod';
import { encryptWithPem } from '@/utils/encryptUtils';
import { CreatePaymentMethodSteps } from '../CreateAdyenPaymentMethodConstants';
import { AdyenEFTMandateModal } from './AdyenEFTMandateModal/AdyenEFTMandateModal';

export interface EncryptedEFTBankData {
    encryptedOwnerName: string,
    encryptedBranchNumber: string,
    encryptedInstitutionNumber: string,
    encryptedAccountNumber: string,
}

export interface CreateEFTBankAccountProps {
    handleClose: () => void,
    customerID: string,
    supplierID: string,
    currentStep: CreatePaymentMethodSteps,
    setCurrentStep: (step: CreatePaymentMethodSteps) => void,
}

export type BankAccountDetails = {
    ownerName: string,
    branchNumber: string,
    institutionNumber: string,
    accountNumber: string,
    confirmAccountNumber: string,
};

const NUM_FIELDS = 5;

export const validationSchema = z.object({
    ownerName: z.string().nonempty('Name of account holder is required').min(2, 'Must be full name'),
    branchNumber: z.string()
        .nonempty('Branch number is required')
        .length(5, 'Must be 5 digits')
        .regex(/^\d+$/, 'Must contain only numbers'),
    institutionNumber: z.string()
        .nonempty('Institution number is required')
        .length(3, 'Must be 3 digits')
        .regex(/^\d+$/, 'Must contain only numbers'),
    accountNumber: z.string()
        .nonempty('Account number is required')
        .min(7, 'Must be 7-12 digits')
        .max(12, 'Must be 7-12 digits')
        .regex(/^\d+$/, 'Must contain only numbers'),
    confirmAccountNumber: z.string()
        .nonempty('Confirm account number is required')
        .min(7, 'Must be 7-12 digits')
        .max(12, 'Must be 7-12 digits')
        .regex(/^\d+$/, 'Must contain only numbers')
}).refine((data) => data.confirmAccountNumber === data.accountNumber, {
    message: 'Account numbers must match',
    path: ['confirmAccountNumber'],
});

export const CreateEFTBankAccount = ({ handleClose, customerID, supplierID, currentStep, setCurrentStep }: CreateEFTBankAccountProps): JSX.Element => {
    const [bankData, setBankData] = useState<EncryptedEFTBankData>(null);

    const emptyBankAccount = {
        ownerName: '',
        branchNumber: '',
        institutionNumber: '',
        accountNumber: '',
        confirmAccountNumber: ''
    };

    const { handleSubmit, control, reset } = useForm<BankAccountDetails>({
        resolver: zodResolver(validationSchema),
        defaultValues: emptyBankAccount,
        mode: 'onBlur',
    });

    const { errors, touchedFields, isDirty } = useFormState({
        control,
    });

    const resetForm = (): void => {
        reset();
    };

    const handleOnClose = ():void => {
        resetForm();
        handleClose();
    };

    const encrypt = async (newFormBankAccount: BankAccountDetails): Promise<EncryptedEFTBankData> => {
        const { ownerName, branchNumber, institutionNumber, accountNumber } = newFormBankAccount;
        const encryptedOwnerName = await encryptWithPem(ownerName);
        const encryptedBranchNumber = await encryptWithPem(branchNumber);
        const encryptedInstitutionNumber = await encryptWithPem(institutionNumber);
        const encryptedAccountNumber = await encryptWithPem(accountNumber);
        return {
            encryptedOwnerName,
            encryptedBranchNumber,
            encryptedInstitutionNumber,
            encryptedAccountNumber
        };
    };

    const onSubmit = async (newFormBankAccount: BankAccountDetails): Promise<void> => {
        setBankData(await encrypt(newFormBankAccount));
        setCurrentStep(CreatePaymentMethodSteps.EFT_MANDATE);
        resetForm();
    };

    const hasErrors = Object.keys(errors).some((key) => errors[key]);
    const allFieldsTouched = Object.keys(touchedFields).length === NUM_FIELDS && Object.values(touchedFields).every((value) => value);
    const isSubmitDisabled = hasErrors || !allFieldsTouched || !isDirty;

    return <> {currentStep === CreatePaymentMethodSteps.ADD_EFT
        && <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-8 py-6 min-w-0">
                <div className="flex-col flex gap-4">
                    <Typography weight="font-regular" variant="BASE" desktopSize="lg:text-3.5" className="m-0">Enter your customer&apos;s bank account information.</Typography>
                    <Controller
                        name="ownerName"
                        control={control}
                        render={({ field }) => <Input
                            name="ownerName"
                            id="ownerName"
                            label="Name of account holder"
                            type="TEXT"
                            inputProps={{
                                placeholder: 'Must match name on account',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.ownerName}
                            invalidMessage={errors.ownerName?.message}
                            labelIcon={touchedFields.ownerName && !errors.ownerName ? <CheckIcon data-testid="owner-name-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="branchNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="branchNumber"
                            id="branchNumber"
                            label="Branch number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '5 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.branchNumber}
                            invalidMessage={errors.branchNumber?.message}
                            labelIcon={touchedFields.branchNumber && !errors.branchNumber ? <CheckIcon data-testid="branch-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="institutionNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="institutionNumber"
                            id="institutionNumber"
                            label="Institution number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '3 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.institutionNumber}
                            invalidMessage={errors.institutionNumber?.message}
                            labelIcon={touchedFields.institutionNumber && !errors.institutionNumber ? <CheckIcon data-testid="institution-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="accountNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="accountNumber"
                            id="accountNumber"
                            label="Account number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '7-12 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.accountNumber}
                            invalidMessage={errors.accountNumber?.message}
                            labelIcon={touchedFields.accountNumber && !errors.accountNumber ? <CheckIcon data-testid="account-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <Controller
                        name="confirmAccountNumber"
                        control={control}
                        render={({ field }) => <Input
                            name="confirmAccountNumber"
                            id="confirmAccountNumber"
                            label="Confirm account number"
                            type="NUMBER"
                            inputProps={{
                                placeholder: '7-12 digits',
                                ...field
                            }}
                            variant="MEDIUM"
                            inputClassName="h-9 text-3.5"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            className="gap-1"
                            required
                            isInvalid={!!errors.confirmAccountNumber}
                            invalidMessage={errors.confirmAccountNumber?.message}
                            labelIcon={touchedFields.confirmAccountNumber && !errors.confirmAccountNumber ? <CheckIcon data-testid="confirm-account-number-check" className="h-4 w-4 text-green-400" /> : null} />} />
                    <ExampleCheck/>
                </div>
            </div>

            <Separator />
            <div className="pt-4 px-6 pb-1 flex justify-end gap-3">
                <Button variant="TERTIARY_FILLED"
                    as={'span'}
                    className="cursor-pointer"
                    size="SMALL"
                    onClick={handleOnClose}>
                    <Typography as="span" weight="font-medium">
                            Cancel
                    </Typography>
                </Button>
                <Button variant="SECONDARY"
                    size="SMALL"
                    disabled={isSubmitDisabled}
                    type="submit">
                    <div className="flex gap-1">
                        <Typography as="span" weight="font-medium">
                                Save & next
                        </Typography>
                        <ArrowRightIcon className="w-4 h-4 mt-0.5" />
                    </div>
                </Button>
            </div>
        </form>}
    {currentStep === CreatePaymentMethodSteps.EFT_MANDATE
    && <AdyenEFTMandateModal
        handleClose={() => {
            setCurrentStep(CreatePaymentMethodSteps.SELECT_PAYMENT_METHOD);
            handleClose();
        }}
        bankData={bankData}
        customerID={customerID}
        supplierID={supplierID}/>
    }
    </>;
};
