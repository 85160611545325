import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, toast, Typography } from '@notch-ordering/ui-components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import CheckIcon from '@icons/check-icon.svg';
import AlertIcon from '@icons/alert-icon.svg';
import { DEBIT_PAYMENT_METHOD_BRANDS } from '@v2/utils/PaymentMethodUtils';
import { Mandate, MandateResponse, MandateStatus, respondMandate } from '@/ar/network/AccountsReceivable.network';
import { AcceptMandateFormSuccess } from './AcceptMandateFormSuccess';

export interface AcceptMandateFormProps {
    mandate: Mandate,
}

export const acceptMandateFormSchema = z.object({
    acceptedBy: z.string({
        required_error: 'Your name is required',
    }).min(1, {
        message: 'Your name is required',
    }),
    jobTitle: z.string({
        required_error: 'Your job title is required',
    }).min(1, {
        message: 'Your job title is required',
    }),
});

export type AcceptMandateFormState = z.infer<typeof acceptMandateFormSchema>;

export const AcceptMandateForm : React.FC<AcceptMandateFormProps> = ({ mandate }) => {
    const respondMandateMutation = useMutation(respondMandate, {
        onSuccess: () => {
            toast.show({
                message: 'Bank account successfully connected',
                icon: <CheckIcon />,
                showClose: false,
            });
        },
        onError: () => {
            toast.show({
                message: 'Unable to connect bank account. Please try again or contact support.',
                icon: <AlertIcon className="text-red-300" />,
                showClose: false,
            });
        },
    });
    const isMandateAccepted = mandate.status === MandateStatus.ACCEPTED;
    const showSuccessView = respondMandateMutation.isSuccess || isMandateAccepted;

    const handleRespondMandate = (response: MandateResponse, acceptedBy: string, jobTitle: string) => {
        respondMandateMutation.mutate({
            mandateID: mandate.id,
            body: {
                response,
                responder_name: acceptedBy,
                responder_job_title: jobTitle
            },
        });
    };

    const { handleSubmit, formState: { errors }, control } = useForm<AcceptMandateFormState>({
        resolver: zodResolver(acceptMandateFormSchema),
    });

    const onSubmit = (formData: AcceptMandateFormState) => {
        handleRespondMandate(MandateResponse.ACCEPTED, formData.acceptedBy, formData.jobTitle);
    };

    const supplierName = mandate?.mandateDetails.emailData.supplierName;

    const achBlurbCopy = `This authorization lets you or us (${supplierName}) initiate payments for any charges related to your purchase and service with us. 
    Funds will be debited from your account detailed in accordance to terms set by ${supplierName}. This will continue until you revoke the authorization. 
    You’re always in control—if you need to update or cancel this authorization, simply provide us with a 30-day notice.`;

    const eftBlurbCopy = `You authorize ${supplierName} to initiate charges to withdraw funds from your bank account for amounts owed for services or products as defined by Payments Canada Rule H1. 
    You waive the rights to advance notice or timing. The first debit will occur upon acceptance of this agreement, with confirmation provided within five (5) days. 
    To cancel, provide 30 days’ notice to ${supplierName}, your bank, or visit www.payments.ca. You have recourse rights for unauthorized debits — contact your bank or visit www.payments.ca. For questions, email support@notch.financial.`;

    return <>
        {(respondMandateMutation.isSuccess || showSuccessView) && <AcceptMandateFormSuccess mandate={mandate} />}
        {!respondMandateMutation.isSuccess && !showSuccessView && <div className="space-y-4 py-8">
            <Typography variant="XL" weight="font-medium" className="mb-4 m-0">Accept your agreement to enable unlimited online Bank Transfers with no fees</Typography>
            <Typography variant="BASE" className="mb-0 text-gray-600 font-medium">{mandate?.paymentMethod.brand === DEBIT_PAYMENT_METHOD_BRANDS.eft ? eftBlurbCopy : achBlurbCopy}</Typography>

            {mandate?.paymentMethod.brand === DEBIT_PAYMENT_METHOD_BRANDS.ach
                            && <div className="flex gap-40 mt-4">
                                <div>
                                    <Typography variant="BASE" className="mb-0 text-gray-600 font-medium">Routing Number</Typography>
                                    <Typography className="mb-0">*****{mandate.paymentMethod.routingNumber}</Typography>
                                </div>
                                <div>
                                    <Typography variant="BASE" className="mb-0 text-gray-600 font-medium">Account Number</Typography>
                                    <Typography className="mb-0">*****{mandate.paymentMethod.last4}</Typography>
                                </div>
                            </div>}
            {mandate?.paymentMethod.brand === DEBIT_PAYMENT_METHOD_BRANDS.eft
                            && <div className="flex gap-10 mt-4">
                                <div>
                                    <Typography variant="BASE" className="mb-0 text-gray-600 font-medium">Institution</Typography>
                                    <Typography className="mb-0">{mandate.paymentMethod.routingNumber.split('-')[0]}</Typography>
                                </div>
                                <div>
                                    <Typography variant="BASE" className="mb-0 text-gray-600 font-medium">Branch</Typography>
                                    <Typography className="mb-0">{mandate.paymentMethod.routingNumber.split('-')[1]}</Typography>
                                </div>
                                <div>
                                    <Typography variant="BASE" className="mb-0 text-gray-600 font-medium">Account Number</Typography>
                                    <Typography className="mb-0">*****{mandate.paymentMethod.last4}</Typography>
                                </div>
                            </div>}

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <Controller
                    control={control}
                    name="acceptedBy"
                    render={({ field }) => (
                        <Input required
                            inputClassName="h-9 text-3.5"
                            placeholder="Enter your name"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            label="Your name"
                            isInvalid={!!errors.acceptedBy}
                            invalidMessage={errors.acceptedBy?.message}
                            value={field.value}
                            onChange={field.onChange} />
                    )} />
                <Controller
                    control={control}
                    name="jobTitle"
                    render={({ field }) => (
                        <Input required
                            inputClassName="h-9 text-3.5"
                            placeholder="Enter your job title"
                            fontSize="[&>label]:font-medium [&>label]:lg:text-1 [&>label]:text-gray-600 [&>label]:m-0"
                            label="Job title"
                            isInvalid={!!errors.jobTitle}
                            invalidMessage={errors.jobTitle?.message}
                            value={field.value}
                            onChange={field.onChange} />
                    )} />
                <Button variant="SECONDARY"
                    disabled={respondMandateMutation.isLoading}
                    loading={respondMandateMutation.isLoading}
                    className="w-full"
                    type="submit">
                    <Typography as="span" weight="font-medium">
                        Accept
                    </Typography>
                </Button>
            </form>
        </div>}
    </>;
};
