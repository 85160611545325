import { Button, Typography } from '@notch-ordering/ui-components';
import LockIcon from '@icons/lock.svg';

import AmexIcon from '@icons/amex-icon.svg';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import BuildingBalanceIcon from '@icons/building-balance-icon.svg';
import CardIcon from '@icons/card-icon.svg';
import MastercardIcon from '@icons/mastercard-icon.svg';
import VisaIcon from '@icons/visa-icon.svg';
import cx from 'classnames';
import React, { useState } from 'react';
import { RadioButton } from '@/components/shared';
import { PaymentMethodType } from '@/ar/network/AccountsReceivable.network';

export interface CreateAdyenPaymentMethodSelectionStepProps {
    showCreditCardButton: boolean,
    showACHButton: boolean,
    hasNoPaymentMethodsEnabled: boolean,
    showEFTButton: boolean,
    onACHSelect: () => void,
    onCardSelect: () => void,
    onEFTSelect: () => void,
}

const ACHNewBanner : React.FC = () => <>
    <div className="flex flex-row new-banner">
        <Typography variant="BASE" className="font-bold text-purple-900">
                        NEW! &nbsp;
        </Typography>
        <Typography variant="BASE" className="font-noraml text-purple-900">
                        You can now set up bank payment on behalf of your customers.
        </Typography>
    </div>
</>;

const EmptyState : React.FC = () => <>
    <Typography className="text-gray-600">
        Payment methods are not yet enabled for the current supplier. Please contact&nbsp;
        <a target="_blank"
            rel="noreferrer"
            className="text-plum-300 hover:text-plum-400"
            href="mailto:support@notch.financial?subject=Customer%20payment%20methods%20not%20enabled">customer support</a>
        &nbsp;to enable them.
    </Typography>
</>;

const SecurityFooter : React.FC = () => <>
    <div className="flex flex-row items-center mt-2">
        <LockIcon className="h-4 w-4 mr-1" />
        <Typography variant="BASE" className="text-gray-600 mb-0">Securely store your payment information</Typography>
    </div>
</>;

export const CreateAdyenPaymentMethodSelectionStep : React.FC<CreateAdyenPaymentMethodSelectionStepProps> = ({
    showCreditCardButton,
    showACHButton,
    onCardSelect,
    onACHSelect,
    hasNoPaymentMethodsEnabled,
    showEFTButton,
    onEFTSelect
}) => {
    const [selected, setSelected] = useState<PaymentMethodType>(null);

    return (
        <>
            <div className="flex flex-col gap-4 px-8 my-8">
                {showACHButton && <ACHNewBanner />}
                <div className="flex flex-col gap-2 items-center ">
                    {showCreditCardButton && <Button
                        className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
                        variant="TERTIARY_OUTLINED"
                        size="MEDIUM"
                        onClick={() => setSelected(PaymentMethodType.CARD)}>
                        <RadioButton id="add-credit-card-payment-method"
                            name="credit-card"
                            label=""
                            isChecked={selected === PaymentMethodType.CARD}
                            onChecked={() => setSelected(PaymentMethodType.CARD)} />
                        <div className="flex gap-3 items-center">
                            <CardIcon className="h-4 w-4" /> Credit Card
                            <VisaIcon className="h-5 w-5" />
                            <MastercardIcon className="h-5 w-5" />
                            <AmexIcon className="h-5 w-5" />
                        </div>
                    </Button>}
                    {showACHButton && <Button
                        className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
                        variant="TERTIARY_OUTLINED"
                        size="MEDIUM"
                        onClick={() => setSelected(PaymentMethodType.US_BANK_ACCOUNT)}>
                        <RadioButton id="add-credit-card-payment-method"
                            name="ach"
                            label=""
                            isChecked={selected === PaymentMethodType.US_BANK_ACCOUNT}
                            onChecked={() => setSelected(PaymentMethodType.US_BANK_ACCOUNT)} />
                        <div className="flex gap-3">
                            <BuildingBalanceIcon
                                className="h-4 w-4" /> Set up US Bank Account
                        </div>
                    </Button>}
                    {showEFTButton && <Button
                        className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
                        variant="TERTIARY_OUTLINED"
                        size="MEDIUM"
                        onClick={() => setSelected(PaymentMethodType.ACSS_DEBIT)}>
                        <RadioButton id="add-eft-payment-method"
                            name="eft"
                            label=""
                            isChecked={selected === PaymentMethodType.ACSS_DEBIT}
                            onChecked={() => setSelected(PaymentMethodType.ACSS_DEBIT)} />
                        <div className="flex gap-3">
                            <BuildingBalanceIcon
                                className="h-4 w-4" /> Set up a Canadian chequing account 🇨🇦
                        </div>
                    </Button>}

                    {hasNoPaymentMethodsEnabled && <EmptyState />}
                    {!hasNoPaymentMethodsEnabled && <SecurityFooter />}
                </div>
            </div>
            {!hasNoPaymentMethodsEnabled && <div className="w-full h-px bg-gray-200" />}
            {!hasNoPaymentMethodsEnabled && <div className="my-4 mx-4 flex justify-end">
                <Button
                    disabled={selected === null}
                    variant={'SECONDARY'}
                    size="MEDIUM"
                    minWidth="w-auto"
                    type="submit"
                    className={cx('flex items-center')}
                    onClick={() => {
                        if (selected === PaymentMethodType.CARD) {
                            onCardSelect();
                        } else if (selected === PaymentMethodType.US_BANK_ACCOUNT) {
                            onACHSelect();
                        } else if (selected === PaymentMethodType.ACSS_DEBIT) {
                            onEFTSelect();
                        }
                    } }>
                        Next<ArrowRightIcon className="h-5 w-5 ml-1" />
                </Button>
            </div>}
        </>
    );
};
