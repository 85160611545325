import { Maintenance } from '@v2/Pages/Maintenance/Maintenance';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';

interface MaintenanceModeProps {
    children: React.ReactNode,
}

export const MaintenanceMode: React.FC<MaintenanceModeProps> = ({ children }) => {
    const flags = useFlags();

    if (flags.maintenanceModeApp) {
        return <Maintenance />;
    }

    return <>{children}</>;
};
