import SuccessMandateImg from '@ar/assets/images/success-mandate.svg';
import { Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { Mandate } from '@/ar/network/AccountsReceivable.network';

export interface AcceptMandateFormSuccessProps {
    mandate: Mandate,
}

export const AcceptMandateFormSuccess : React.FC<AcceptMandateFormSuccessProps> = ({ mandate }) => <>
    <div className="space-y-4 py-8">
        <SuccessMandateImg className="w-[136px] h-[105px]" />
        <Typography variant="XL" className="mb-0 font-medium">Success</Typography>
        <Typography variant="LG-1" className="mb-0">
                You have enabled Bank Transfers as a payment option for account ending {mandate?.paymentMethod?.last4}.
        </Typography>
        <Typography variant="LG-1" className="mb-0">
        Enjoy the benefits of unlimited and secure payments with no fees.
        </Typography>
        <Typography variant="LG-1" className="mb-0">
                Thank you!
        </Typography>
    </div>
</>;
