import React from 'react';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Button } from '@notch-ordering/ui-components';
import cx from 'classnames';
import CardIcon from '@icons/card-icon.svg';
import { useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { useGetSupplier } from '@ar/hooks/queries/SupplierQueries.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '@/constants/FeatureFlags';
import { ActivePaymentMethods } from '@/utils/constants';
import { CreateStripeCardPaymentMethod } from '@/ar/components/shared/modals/CreateStripePaymentMethod/CreateStripeCardPaymentMethod';
import { CreateAdyenCardPaymentMethod } from '@/ar/components/shared/modals/CreateAdyenPaymentMethod/CreateAdyenCardPaymentMethod/CreateAdyenCardPaymentMethod';

export interface CreditCardButtonProps {
    onClose?: () => void,
}

export const CreditCardButton : React.FC<CreditCardButtonProps> = ({ onClose }: CreditCardButtonProps) => {
    const [isAddPaymentOpen, setIsAddPaymentOpen] = React.useState<boolean>(false);
    const { supplierLoginData } = useSupplierStore();
    const flags = useFlags<FeatureFlags>();
    const enableAddPaymentMethodsWithAdyen = flags?.arPaymentProvider?.card === ActivePaymentMethods.adyen;
    const enableAddPaymentMethodWithStripe = flags?.arPaymentProvider?.card === ActivePaymentMethods.stripe;
    const showStripePaymentMethodModal = enableAddPaymentMethodWithStripe && isAddPaymentOpen;
    const showAdyenPaymentMethodModal = enableAddPaymentMethodsWithAdyen && isAddPaymentOpen;

    const { data: customerSupplierMappingsResponse } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });
    // here we take first connected supplier
    // because  we need to attach payment method to connected supplier
    // only add explanation
    const supplierID = supplierLoginData.supplier_id ?? customerSupplierMappingsResponse?.suppliers[0]?.id ?? '';
    useGetSupplier({
        supplierID,
        customerId: supplierLoginData?.customer_id
    });
    if (!supplierID) return null;
    return <>
        <Button
            className={cx('text-left flex gap-2 items-center px-4 py-3 text-gray-700 w-full')}
            variant="TERTIARY_OUTLINED"
            size="MEDIUM"
            onClick={(): void => {
                setIsAddPaymentOpen(true);
            }}>
            <div className="flex gap-3 items-center">
                <CardIcon
                    className="h-4 w-4"/> Setup a Credit Card
            </div>
        </Button>
        {showStripePaymentMethodModal
                    && <CreateStripeCardPaymentMethod isOpen={isAddPaymentOpen}
                        handleClose={(): void => {
                            if (onClose) {
                                onClose();
                            }
                            setIsAddPaymentOpen(false);
                        }}
                        supplierID={supplierID}
                        customerID={supplierLoginData.customer_id}/>
        }
        {showAdyenPaymentMethodModal
                    && <CreateAdyenCardPaymentMethod isOpen={isAddPaymentOpen}
                        handleClose={(): void => {
                            if (onClose) {
                                onClose();
                            }
                            setIsAddPaymentOpen(false);
                        }}
                        supplierID={supplierID}
                        customerID={supplierLoginData.customer_id}/>
        }
    </>;
};
