async function importPemKey(pem: string): Promise<CryptoKey> {
    // Remove the PEM header and footer
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    const pemContents = pem.replace(pemHeader, '').replace(pemFooter, '').replace(/\s/g, '');

    // Decode base64
    const binaryDer = Uint8Array.from(atob(pemContents), (char) => char.charCodeAt(0));

    // Import the key
    return crypto.subtle.importKey(
        'spki',
        binaryDer.buffer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        false,
        ['encrypt']
    );
}

export const encryptWithPem = async (data: string): Promise<string> => {
    const pemBase64 = process.env.PAYMENT_PROCESSOR_ENCRYPTION_PEM || '';
    const pemKey = Buffer.from(pemBase64, 'base64').toString('utf-8');

    const publicKey = await importPemKey(pemKey);
    const encodedData = new TextEncoder().encode(data);

    const encryptedBuffer = await crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
        },
        publicKey,
        encodedData
    );

    // Convert ArrayBuffer to Base64
    const encryptedArray = new Uint8Array(encryptedBuffer);
    const encryptedBase64 = btoa(String.fromCharCode(...encryptedArray));

    return encryptedBase64;
};
