// Payment method flow:
// Credit Card:
// 1. SELECT_PAYMENT_METHOD -> 2. ADD_CREDIT_CARD -> on success or close -> close modal
// ACH:
// 1. SELECT_PAYMENT_METHOD -> 2. ADD_ACH -> 3. ACH_MANDATE -> on success or close -> close modal
// EFT:
// 1. SELECT_PAYMENT_METHOD -> 2. ADD_EFT -> 3. EFT_MANDATE -> on success or close -> close modal

import { PaymentTypes, CorePaymentType } from '@/ar/network/AccountsReceivable.network';

export enum CreatePaymentMethodSteps {
    SELECT_PAYMENT_METHOD = 1,
    ADD_CREDIT_CARD = 2,
    ADD_ACH = 3,
    ACH_MANDATE = 4,
    ADD_EFT = 5,
    EFT_MANDATE = 6,
}

export enum MandateTypes {
    EMAIL = 'email',
    OFFLINE = 'offline'
}

/**
 * List of supported credit card payment types
 */
const creditCardPaymentTypes = [PaymentTypes.Visa, PaymentTypes.Mc, PaymentTypes.Amex];

/**
 * Checks if any credit card payment types (Visa, Mastercard, Amex) are enabled
 *
 * @param paymentTypes - Array of payment type configurations from the API
 * @returns boolean indicating if any credit card payments are enabled
 */
export const isCreditCardEnabledAsPaymentType = (paymentTypes: CorePaymentType[] = []) => paymentTypes.some((paymentType) => creditCardPaymentTypes.includes(paymentType.method.type) && paymentType.status === 'active');

/**
 * Checks if ACH (US bank account) payments are enabled
 *
 * @param paymentTypes - Array of payment type configurations from the API
 * @returns boolean indicating if ACH payments are enabled
 */
export const isACHEnabledAsPaymentType = (paymentTypes: CorePaymentType[] = []) => paymentTypes.some((paymentType) => paymentType.method.type === PaymentTypes.Ach && paymentType.status === 'active');

/**
 * Checks if EFT payments are enabled
 *
 * @param paymentTypes - Array of payment type configurations from the API
 * @returns boolean indicating if EFT payments are enabled
 */
export const isEFTEnabledAsPaymentType = (paymentTypes: CorePaymentType[] = []) => paymentTypes.some((paymentType) => paymentType.method.type === PaymentTypes.Acss && paymentType.status === 'active');
