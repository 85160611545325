import { Modal } from '@notch-ordering/ui-components';
import { ModalProps } from '@notch-ordering/ui-components/dist/components/Modal/ModalConstants';
import React from 'react';

export type ConditionalModalWrapperProps = { children: React.ReactNode, isModal?: boolean } & ModalProps;
/**
 * A wrapper component that conditionally renders its children inside a modal or as a fragment.
 */
export const ConditionalModalWrapper: React.FC<ConditionalModalWrapperProps> = ({ children, isModal = true, ...modalProps }) => (isModal ? (
    <Modal {...modalProps}>
        {children}
    </Modal>
) : (
    <React.Fragment>
        {children}
    </React.Fragment>
));
