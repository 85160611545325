import { getAdyenPaymentMethodType, GetAdyenPaymentMethodTypeParams, getPaymentMethodSchema, GetPaymentMethodSchemaParams } from '@ar/network/AccountsReceivable.network';
import { useQuery } from '@tanstack/react-query';

export const FETCH_PAYMENT_METHOD_SCHEMA_KEY = 'FETCH_PAYMENT_METHOD_SCHEMA_KEY';

export const useGetPaymentMethodSchema = (params: GetPaymentMethodSchemaParams) => useQuery([FETCH_PAYMENT_METHOD_SCHEMA_KEY, params], async () => getPaymentMethodSchema(params), { enabled: !!params.paymentReceiverID || !!params.supplierID });

export const FETCH_ADYEN_PAYMENT_METHOD_TYPE_KEY = 'FETCH_ADYEN_PAYMENT_METHOD_TYPE_KEY';

export const useGetAdyenPaymentMethodType = (params: GetAdyenPaymentMethodTypeParams) => useQuery(
    [FETCH_ADYEN_PAYMENT_METHOD_TYPE_KEY, params],
    async () => getAdyenPaymentMethodType(params),
    { enabled: !!params.paymentReceiverId && (!!params.supplierId || !!params.customerId) }
);
