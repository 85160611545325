import { Loading, mergeClassNames, Typography } from '@notch-ordering/ui-components';
import { EPaths } from '@v2/constants/EPaths';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useGetMandate } from '@/ar/hooks/queries/MandateQueries.hook';
import { AcceptMandateForm } from '@/ar/components/MandatePage/AcceptMandateForm/AcceptMandateForm';
import { MandateStatus } from '@/ar/network/AccountsReceivable.network';

export const AcceptMandatePage : React.FC<{ className?: string }> = ({ className }) => {
    const { id } = useParams();

    const { data: mandate, isLoading } = useGetMandate({ mandateID: id as string });

    if (isLoading) {
        return <Loading />;
    }

    if (!mandate || mandate?.status === MandateStatus.DECLINED) {
        return <Navigate to={EPaths.SIGN_IN} replace />;
    }

    return <>
        <div className={mergeClassNames('lg:w-[576px] w-full px-8', className)}>
            <div className="flex items-center gap-1 pb-4  mt-16">
                <img src={mandate?.mandateDetails.emailData.supplierLogo} alt="Supplier Logo" className="w-10 h-10 object-contain" />
                <Typography variant="4XL" weight="font-medium" className="mb-0 text-6 lg:text-6">{mandate?.mandateDetails.emailData.supplierName}</Typography>
            </div>
            <AcceptMandateForm mandate={mandate} />
        </div>

    </>;
};
