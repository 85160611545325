import MaintenanceImg from '@images/maintenance.svg';
import { Typography } from '@notch-ordering/ui-components';
import React, { FC } from 'react';

export const Maintenance: FC = () => (
    <div className="flex justify-center items-center flex-col h-full w-full gap-9 bg-white px-8 sm:px-36 text-[16px]">
        <div className="w-[274px] h-[210px]">
            <MaintenanceImg />
        </div>
        <Typography
            variant="3XL"
            as="h3"
            className="text-center text-gray-700 m-0 p-0">
                    Scheduled maintenance
        </Typography>

        <Typography
            as="div"
            className="sm:text-[18px] m-0 text-gray-700 text-center ">
                We’re making some upgrades to enhance your experience. Our platform will be back online shortly.<br/><br/>
                For urgent requests, feel free to reach out to us at <a href="mailto:support@notch.financial" target="_blank" rel="noreferrer noopener">support@notch.financial</a>
        </Typography>
    </div>
);
