import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Popover, toast, Typography, Loading, Tooltip, Badge, PopoverItem } from '@notch-ordering/ui-components';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import WarnIcon from '@icons/warning-filled-icon.svg';
import { deletePaymentMethod, GetPaymentMethodResponse, setDefaultPaymentMethodAsCustomer, sendMandateEmail, PaymentMethodType } from '@ar/network/AccountsReceivable.network';
import { ConfirmModal } from '@ar/components/shared/ConfirmModal';
import PaymentMethodBadge, { getCCIcon } from '@ar/components/shared/PaymentMethodBadge/PaymentMethodBadge';
import { FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY, FETCH_CUSTOMER_QUERY_KEY } from '@ar/hooks/queries/CustomerQueries.hook';
import { trackCurrentPageTitle, TrackingEvents } from '@ar/constants/TrackingConstants';
import { getPaymentMethodBrandDisplayString, isCardExpired, isPendingMandatePaymentMethod } from '@v2/utils/PaymentMethodUtils';
import { FilterLabel } from '@v2/components/Shared/FilterLabel/FilterLabel';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { AxiosError } from 'axios';
import { queryClient } from '@/containers/app/Root';

interface Props {
    paymentMethod: GetPaymentMethodResponse,
    paymentMethods: GetPaymentMethodResponse[],
    customerID: string,
    supplierID: string,
    hasOnlyOnePaymentMethod: boolean,
    onMakeDefault: () => void,
}

export const PaymentMethod = ({ paymentMethod, paymentMethods, customerID, supplierID, hasOnlyOnePaymentMethod, onMakeDefault }: Props): JSX.Element => {
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [shouldShowDefaultPaymentMethodTooltip, setShouldShowDefaultPaymentMethodTooltip] = useState(false);
    const [shouldShowExpiredPaymentMethodTooltip, setShouldShowExpiredPaymentMethodTooltip] = useState(false);
    const [shouldShowPendingMandateTooltip, setShouldShowPendingMandateTooltip] = useState(false);
    const [defaultMethod, setDefaultMethod] = useState<GetPaymentMethodResponse>(null);
    const defaultPaymentMethodTooltipText = 'Your default payment method can be used to pay any of your supplier bills.';
    const expiredPaymentMethodTooltipText = 'This card has expired. Please delete this card and add a new one for future payments.';
    const pendingMandateTooltipText = 'Bank account cannot be charged until PAD agreement is accepted.';

    const deleteMethodMutation = useMutation(deletePaymentMethod, {
        onSuccess: () => {
            setIsDeleteConfirmOpen(false);
            toast.show({
                message: 'Payment method deleted',
                icon: <CheckIcon/>,
            });
            queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
        },
        onError: () => {
            toast.show({
                message: 'Failed to delete payment method, please refresh the page and try again.',
            });
        },
    });

    const sendMandateEmailMutation = useMutation(sendMandateEmail, {
        onSuccess: () => {
            toast.show({
                message: 'Debit agreement sent',
                icon: <CheckIcon/>,
            });
            queryClient.invalidateQueries([FETCH_CUSTOMERS_PAYMENT_METHODS_QUERY_KEY]);
        },
        onError: (response) => {
            const { data } = (response as AxiosError).response;
            const { error } = data as { error: string };
            toast.show({
                message: `Error sending mandate email: ${error}`,
            });
        },
    });

    const setDefaultPaymentMethodMutation = useMutation(
        setDefaultPaymentMethodAsCustomer,
    );

    const makeDefault = (method: GetPaymentMethodResponse): void => {
        if (method?.id) {
            setDefaultPaymentMethodMutation.mutate({ paymentMethodID: method?.id, customerID, body: { customer_id: customerID, is_primary: true } }, {
                onSuccess: async () => {
                    await queryClient.invalidateQueries([FETCH_CUSTOMER_QUERY_KEY, customerID, supplierID]);
                    toast.show({
                        message: 'Default payment method successfully updated',
                        icon: <CheckIcon />,
                        showClose: false,
                    });
                    onMakeDefault();
                },
                onError: () => {
                    toast.show({
                        message: 'Error while updating default payment method',
                        showClose: false,
                    });
                },
            });
        }
    };

    const remainingPaymentMethods = paymentMethods?.filter((method) => !method.is_primary);

    const handleClickDelete = ():void => {
        setIsDeleteConfirmOpen(true);
        setDefaultMethod(remainingPaymentMethods[0]);
    };

    const handleCancel = ():void => {
        setIsDeleteConfirmOpen(false);
    };

    const handleAcceptDelete = ():void => {
        if (paymentMethod.is_primary) {
            makeDefault(defaultMethod);
        }
        deleteMethodMutation.mutate({ paymentMethodID: paymentMethod.id, customerID, supplierID });
    };

    const handleClickMakeDefault = ():void => {
        makeDefault(paymentMethod);
    };

    const handleClickSendMandate = ():void => {
        sendMandateEmailMutation.mutate({ paymentMethodID: paymentMethod.id, customerID, supplierID });
    };

    const isCardLoaded = !!paymentMethod.last4;

    const paymentMethodOptions = (isShowCheck: boolean = true): PopoverItem[] => remainingPaymentMethods.map((method) => ({
        label: <FilterLabel
            label={<PaymentMethodBadge key={method.id} brand={method.brand} last4={method.last4} mandate_status={method.mandate_status}/>}
            isChecked={isShowCheck && method.id === defaultMethod?.id}/>,
        onClick: (): void => {
            setDefaultMethod(method);
        },
    }));
    const isNotBankAccount = paymentMethod.type !== 'us_bank_account' && paymentMethod.type !== 'acss_debit';
    const notAbleToSendMandateEmail = !paymentMethod.mandate_status || paymentMethod.mandate_status === 'accepted' || isNotBankAccount || paymentMethod.payment_source_id === null;
    const popover = <Popover
        className="w-60"
        button={
            <Button
                variant="LINK"
                className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8 text-black-300"
                size="SMALL"
                onClick={() => trackCurrentPageTitle(TrackingEvents.moreButtonClicked)}
                stopPropagation={false}>
                <div className={'lg:w-4 lg:h-4 w-5 h-5'} data-testid="payment-method-menu">
                    <EllipsisIcon className="h-4 w-4" />
                </div>
            </Button>
        }
        items={
            [
                {
                    label: <div className="flex">
                        <Typography className="flex flex-nowrap gap-3 items-start text-gray-700 m-0">Make default</Typography>
                    </div>,
                    onClick: handleClickMakeDefault,
                    hidden: paymentMethod.is_primary
                },
                {
                    label: <div className="flex">
                        <Typography className="flex flex-nowrap gap-3 items-start text-gray-700 m-0">Send debit agreement</Typography>
                    </div>,
                    onClick: handleClickSendMandate,
                    hidden: notAbleToSendMandateEmail
                },
                {
                    label: <div className="flex">
                        <Typography className="flex flex-nowrap gap-3 items-start text-red-500 m-0">Remove</Typography>
                    </div>,
                    onClick: handleClickDelete,
                },
            ]
        }/>;

    const deletePaymentMethodTitle = 'Delete Payment Method';
    const deletePaymentMethodPrompt = 'Are you sure you want to delete this payment method? Please be aware that if this payment method is set as the "Vendor Default" for any of your suppliers, we will now fall back to your account\'s default payment method.';
    const deleteDefaultPaymentMethodTitle = 'Deleting Your Default Payment Method';
    const deleteDefaultPaymentMethodPrompt = <>
        <p>You are trying to delete a default payment method, which may be used for Autopay.</p>
        <p>Please set a new card as your default payment method.</p>
        {!!defaultMethod?.id && <div className="pb-11">
            <Typography className="text-gray-700" weight="font-medium mb-3">
                New default card
            </Typography>
            <Popover
                className="max-h-[98px] overflow-auto text-base min-w-[332px]"
                placement="bottom-start"
                parentDivClassName="w-full"
                items={paymentMethodOptions()}
                button={<Button
                    as="div"
                    type="button"
                    variant="TERTIARY_OUTLINED"
                    size="SMALL"
                    className="cursor-pointer px-2.5 w-full"
                    stopPropagation={false}>
                    <div className="flex items-center justify-between">
                        <PaymentMethodBadge
                            key={defaultMethod.id}
                            brand={defaultMethod.brand}
                            className="gap-1"
                            last4={defaultMethod.last4}
                            mandate_status={defaultMethod.mandate_status} />
                        <ChevronDownIcon className="w-4 h-4 text-gray-600"/>
                    </div>
                </Button>}/>
        </div>}
    </>;
    const deleteLastPaymentMethodTitle = 'Deleting Last Payment Method';
    const deleteLastPaymentMethodPrompt = 'Warning: This is your only saved payment method. Deleting it may disrupt future transactions. Are you sure you want to proceed?';

    const deleteNonLastPaymentMethodTitle = paymentMethod.is_primary ? deleteDefaultPaymentMethodTitle : deletePaymentMethodTitle;
    const deleteNonLastPaymentMethodPrompt = paymentMethod.is_primary ? deleteDefaultPaymentMethodPrompt : deletePaymentMethodPrompt;
    const deletionModalTitle = <div className="flex flex-col">
        <WarnIcon className="w-8 h-8 mb-2" />{hasOnlyOnePaymentMethod ? deleteLastPaymentMethodTitle : deleteNonLastPaymentMethodTitle}
    </div>;
    const deletionModalPrompt = hasOnlyOnePaymentMethod ? deleteLastPaymentMethodPrompt : deleteNonLastPaymentMethodPrompt;

    let expiryBadge = null;
    if (paymentMethod.type === PaymentMethodType.CARD && paymentMethod.exp_month && paymentMethod.exp_year) {
        const expiryString = `Exp: ${(paymentMethod.exp_month).toString().padStart(2, '0')}/${(paymentMethod.exp_year % 100).toString().padStart(2, '0')}`;
        expiryBadge = isCardExpired(paymentMethod.exp_year, paymentMethod.exp_month)
            ? <Tooltip
                show={shouldShowExpiredPaymentMethodTooltip}
                trigger={<div onMouseEnter={(): void => setShouldShowExpiredPaymentMethodTooltip(true)}
                    onMouseLeave={(): void => setShouldShowExpiredPaymentMethodTooltip(false)}>
                    <Badge variant="RED" fontSize="text-1" fontWeight="font-medium" className="mr-4">{expiryString}</Badge>
                </div>}
                placement="top"
                tooltipClassName="py-1.5 px-2 rounded-md"
                className="text-left"
                showArrow>
                <Typography className="w-60 mb-0">{expiredPaymentMethodTooltipText}</Typography>
            </Tooltip>
            : <Badge variant="GRAY" fontSize="text-1" fontWeight="font-medium" className="text-gray-600 mr-4">{expiryString}</Badge>;
    }

    return (
        <>
            <div className="my-2 ap-payment-method">
                <div className="flex border border-gray-300 p-4 rounded-xl w-full justify-between">
                    <div className="flex gap-5 items-center mr-10">
                        <div className="w-10 h-10 p-0.5 border border-gray-300 rounded-xl">{getCCIcon(paymentMethod.brand, 'w-full h-full disable-svg-stroke')}</div>
                        <div>
                            <div className="mb-2 font-semibold capitalize">{getPaymentMethodBrandDisplayString(paymentMethod)}</div>
                            <div className="flex flex-row gap-2">
                                <div className="flex items-center text-gray-600 font-light">
                                Ending in <span className="mr-1 ml-3">••••</span> {isCardLoaded ? paymentMethod.last4 : <Loading isDark />}
                                </div>
                                {isCardLoaded && expiryBadge}
                            </div>
                        </div>
                    </div>
                    {isCardLoaded && <div className="flex items-center">
                        {paymentMethod.is_primary && <Tooltip
                            show={shouldShowDefaultPaymentMethodTooltip}
                            trigger={<div onMouseEnter={(): void => setShouldShowDefaultPaymentMethodTooltip(true)}
                                onMouseLeave={(): void => setShouldShowDefaultPaymentMethodTooltip(false)}>
                                <Badge variant="GREEN" fontSize="text-1" fontWeight="font-medium" className="mr-4">Default</Badge>
                            </div>}
                            placement="top"
                            tooltipClassName="py-1.5 px-2 rounded-md"
                            className="text-left"
                            showArrow>
                            <Typography className="w-60 mb-0">{defaultPaymentMethodTooltipText}</Typography>
                        </Tooltip>}
                        {isPendingMandatePaymentMethod(paymentMethod) && (
                            <div className="flex ">
                                <Tooltip
                                    show={shouldShowPendingMandateTooltip}
                                    placement="top"
                                    onShow ={(): void => { setShouldShowPendingMandateTooltip(true); }}
                                    onHide ={(): void => { setShouldShowPendingMandateTooltip(false); }}
                                    trigger={
                                        <div>
                                            <Typography className="truncate flex-none items-center mb-0">
                                                <Badge fontSize="text-1" variant="ORANGE" fontWeight="font-medium">
                                    Agreement pending
                                                </Badge>
                                            </Typography>
                                        </div>}>
                                    {pendingMandateTooltipText}
                                </Tooltip></div>)}
                        {popover}
                    </div>}
                </div>
            </div>
            <ConfirmModal
                isOpen={isDeleteConfirmOpen}
                onConfirm={handleAcceptDelete}
                isLoading={deleteMethodMutation.isLoading}
                confirmLabel="Confirm"
                onClose={handleCancel}
                title={deletionModalTitle}
                prompt={deletionModalPrompt}
                confirmVariant="DESTRUCTIVE" />
        </>);
};
