import React from 'react';

import { useGetCustomerSupplierMappings } from '@ar/hooks/queries/CustomerQueries.hook';
import { SIGN_UP_SUPPLIER_ID_KEY } from '@ar/pages/SignUpPage/CustomerSignUpPage';
import { useSupplierStore } from '@ar/stores/SupplierStore';
import { Loading, Modal, Typography } from '@notch-ordering/ui-components';
import { getGpoId } from '@v2/utils/GPOUtils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetSupplier } from '@/ar/hooks/queries/SupplierQueries.hook';
import { useGetAdyenPaymentMethodType } from '@/ar/hooks/queries/PaymentReceiverQueries.hook';
import { drawerInsideClassName } from '@/ar/components/CustomerDetails/CustomerGeneralPopup/CustomerGeneralPopup';
import { CreateACHBankAccount } from './CreateACHBankAccount/CreateACHBankAccount';
import { CreateAdyenCardPaymentMethod } from './CreateAdyenCardPaymentMethod/CreateAdyenCardPaymentMethod';
import { CreatePaymentMethodSteps, isACHEnabledAsPaymentType, isCreditCardEnabledAsPaymentType, isEFTEnabledAsPaymentType } from './CreateAdyenPaymentMethodConstants';
import { CreateAdyenPaymentMethodSelectionStep } from './CreateAdyenPaymentMethodSelectionStep/CreateAdyenPaymentMethodSelectionStep';
import { CreateEFTBankAccount } from './CreateEFTBankAccount/CreateEFTBankAccount';
import './CreatePaymentMethodModal.scss';
import { ActivePaymentMethods } from '@/utils/constants';

export interface PaymentMethodModalProps {
    isOpen?: boolean,
    handleClose?: () => void,
    subTitle?: string,
    customerID?: string,
}

export const getModalTitlePerStep = (currentStep: CreatePaymentMethodSteps, subTitle): React.ReactNode => {
    switch (currentStep) {
        case CreatePaymentMethodSteps.SELECT_PAYMENT_METHOD:
            return <div className="flex flex-col gap-2">
                <Typography variant="2XL" className="font-bold m-0">Add a payment method</Typography>
            </div>;
        case CreatePaymentMethodSteps.ADD_CREDIT_CARD:
            return (
                <>
                    <Typography className="m-0 pr-10 mb-1" weight="font-semibold" variant="LG-2">Create new payment method</Typography>
                    {Boolean(subTitle) && <Typography variant="LG-1" weight="font-regular" className="text-gray-600">{subTitle}</Typography>}
                </>
            );
        case CreatePaymentMethodSteps.ADD_ACH:
            return <div className="flex flex-row gap-2">
                <Typography className="m-0 pr-10" weight="font-medium" variant="LG-2" desktopSize="lg:text-5">Add a US bank account</Typography>
            </div>;

        case CreatePaymentMethodSteps.ACH_MANDATE:
            return <div className="flex flex-row gap-2">
                <Typography className="m-0 pr-10" weight="font-medium" variant="LG-2" desktopSize="lg:text-5">Pre-authorized debit agreement</Typography>
            </div>;
        case CreatePaymentMethodSteps.ADD_EFT:
            return <div className="flex flex-row gap-2">
                <Typography className="m-0 pr-10" weight="font-medium" variant="LG-2" desktopSize="lg:text-5">Add a Canadian bank account</Typography>
            </div>;
        case CreatePaymentMethodSteps.EFT_MANDATE:
            return <div className="flex flex-row gap-2">
                <Typography className="m-0 pr-10" weight="font-medium" variant="LG-2" desktopSize="lg:text-5">Pre-authorized debit agreement</Typography>
            </div>;
        default:
            return <div className="flex flex-col gap-2">
                <Typography variant="2XL" className="font-bold m-0">Add a payment method</Typography>
            </div>;
    }
};

export const CreateAdyenPaymentMethodModal : React.FC<PaymentMethodModalProps> = ({ isOpen, handleClose, subTitle, customerID } : PaymentMethodModalProps) => {
    const { supplierLoginData, supplier } = useSupplierStore();
    const [isCreatePaymentMethodModalOpen, setCreatePaymentMethodModalOpen] = React.useState<boolean>(false);
    const [currentStep, setCurrentStep] = React.useState<CreatePaymentMethodSteps>(CreatePaymentMethodSteps.SELECT_PAYMENT_METHOD);
    const gpoId = getGpoId(supplierLoginData);
    const { data } = useGetCustomerSupplierMappings({
        customerID: supplierLoginData.customer_id
    });
    const flags = useFlags();

    // id is stored in local storage when the customer signs up if not then use the first supplier id from the supplier mappings list (first check if we are a supplier and a customer)
    const supplierID = supplierLoginData.supplier_id ?? window.localStorage.getItem(SIGN_UP_SUPPLIER_ID_KEY) ?? data?.suppliers[0]?.id;
    const { data: adyenPaymentMethodTypeResponse, isLoading: isAdyenPaymentMethodTypeLoading } = useGetAdyenPaymentMethodType({
        paymentReceiverId: supplier.payment_receiver_id,
        supplierId: supplierID,
        customerId: customerID
    });
    useGetSupplier({
        supplierID,
        customerId: supplierLoginData?.customer_id,
        gpoId
    });

    const onClose = () => {
        setCreatePaymentMethodModalOpen(false);
        setCurrentStep(CreatePaymentMethodSteps.SELECT_PAYMENT_METHOD);
        handleClose();
    };

    React.useEffect(() => {
        if (isOpen) {
            setCreatePaymentMethodModalOpen(true);
        }
    }, [isOpen]);

    const hasNoPaymentMethodsEnabled = !supplier?.allow_customer_pay_with_bank_debits && !supplier?.allow_customer_pay_with_cards;
    const isACHEnabled = flags?.arPaymentProvider?.us_bank_account === ActivePaymentMethods.adyen && isACHEnabledAsPaymentType(adyenPaymentMethodTypeResponse?.paymentTypes);
    const isEFTEnabled = flags?.arPaymentProvider?.acss_debit === ActivePaymentMethods.adyen && isEFTEnabledAsPaymentType(adyenPaymentMethodTypeResponse?.paymentTypes);
    const showCreditCardButton = !!supplier?.allow_customer_pay_with_cards && isCreditCardEnabledAsPaymentType(adyenPaymentMethodTypeResponse?.paymentTypes);
    const showACHButton = !!supplier?.allow_customer_pay_with_bank_debits && isACHEnabled;
    const showEFTButton = !!supplier?.allow_customer_pay_with_bank_debits && isEFTEnabled;
    const achSteps = [CreatePaymentMethodSteps.ADD_ACH, CreatePaymentMethodSteps.ACH_MANDATE];
    const eftSteps = [CreatePaymentMethodSteps.ADD_EFT, CreatePaymentMethodSteps.EFT_MANDATE];
    const isCurrentStepInAchFlow = achSteps.includes(currentStep);
    const isCurrentStepInEFTFlow = eftSteps.includes(currentStep);

    if (!isOpen) {
        return null;
    }

    return <Modal
        className={drawerInsideClassName}
        isOpen={isCreatePaymentMethodModalOpen}
        close={onClose}
        onClose={onClose}
        titleSeparatorDesktop={true}
        desktopModalWidth="lg:w-[560px] lg:min-h-[430px] transition-all"
        title={getModalTitlePerStep(currentStep, subTitle)}>
        {isAdyenPaymentMethodTypeLoading && <Loading isDark className="my-8" />}
        {!isAdyenPaymentMethodTypeLoading && (
            <>
                {currentStep === CreatePaymentMethodSteps.SELECT_PAYMENT_METHOD
                    && <CreateAdyenPaymentMethodSelectionStep
                        hasNoPaymentMethodsEnabled={hasNoPaymentMethodsEnabled}
                        onCardSelect={() => setCurrentStep(CreatePaymentMethodSteps.ADD_CREDIT_CARD)}
                        onACHSelect={() => setCurrentStep(CreatePaymentMethodSteps.ADD_ACH)}
                        onEFTSelect={() => setCurrentStep(CreatePaymentMethodSteps.ADD_EFT)}
                        showACHButton={showACHButton}
                        showEFTButton={showEFTButton}
                        showCreditCardButton={showCreditCardButton}/>
                }
                {/* CREDIT CARD FLOW handle one step in the main component */}
                {currentStep === CreatePaymentMethodSteps.ADD_CREDIT_CARD && <CreateAdyenCardPaymentMethod
                    isModal={false}
                    handleClose={onClose}
                    subTitle={subTitle}
                    supplierID={supplierID}
                    customerID={customerID}/>}

                {/* ACH FLOW handle two steps in the main component */}
                {isCurrentStepInAchFlow && <CreateACHBankAccount
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    customerID={customerID}
                    handleClose={onClose}
                    supplierID={supplierID}/>}

                {/* EFT FLOW handle two steps in the main component */}
                {isCurrentStepInEFTFlow && <CreateEFTBankAccount
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    customerID={customerID}
                    handleClose={onClose}
                    supplierID={supplierID}/>}
            </>
        )}
    </Modal>;
};
